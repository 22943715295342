import store from "../store";

let musicRef = null;


const PlayMusic = (audioUrl) => {
  if (!musicRef) {
    musicRef = new Audio(audioUrl);
    musicRef.loop = false;
  } else {
    musicRef.src = audioUrl;
  }
  // Can set lang value in localstorage and access there
  // 1 is normal speed
  if(store?.getState()?.trackingUser?.user?.lang==='hi') {
    musicRef.playbackRate=0.8
  }
  musicRef.play().catch((err) => {});
  return musicRef;
};

export default PlayMusic;
