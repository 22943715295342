import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "./style.scss";
// import UnMuteMusic from "../../../assets/images/audiocontrols/tile000.png";
// import UnMuteAudio from "../../../assets/images/audiocontrols/tile003.png";
// import MuteMusic from "../../../assets/images/audiocontrols/tile001.png";
// import MuteAudio from "../../../assets/images/audiocontrols/tile004.png";
import startVoiceOver from "../../../utils/playVoiceOver";
import { usePageVisibility } from "react-page-visibility";
import music from "../../../assets/audio/BGMusic.mp3";
import { isWindowOpen } from "../../../store/screentransistion/actions";
import {
  toggleMusic,
  toggleVoiceOver,
} from "../../../store/screentransistion/actions";
//assets audio
import optionIcon from "../../../assets/images/option_icon.png";
import hamburgerCloseButtonImage from "../../../assets/images/hamburger_close_button.png";
import optionSlideImage from "../../../assets/images/option_slide.png";
import hamburgerAudioOnButtonImage from "../../../assets/images/hamburger_audio_on_button.png";
import hamdburgerAudioOffButtonImage from "../../../assets/images/hamburger_audio_off_button.png";
//music
import hamburgerMusicOnButtonImage from "../../../assets/images/hamburger_music_on_button.png";
import hamburgerMusicOffButtonImage from "../../../assets/images/hamburger_music_off_button.png";

const AudioControls = ({
  toggleMusic,
  toggleVoiceOver,
  playMusic,
  playVoiceOver,
  isWindowOpen,
  isWindowActive,
}) => {
  const [isOptionSlideVisible, setOptionSlideVisible] = useState(false);
  const [toggleAudio, setToggleAudio] = useState(true);
  const [musicToggle, setMusicToggle] = useState(true);
  const musicRef = useRef(null);
  const isVisible = usePageVisibility();

  useEffect(() => {
    musicRef.current = startVoiceOver(music);
    if (!playMusic || !isVisible) {
      musicRef.current.pause();
    }
    return () => {
      musicRef.current.pause();
    };
    // eslint-disable-next-line
  }, [playMusic, isVisible]);

  useEffect(() => {
    isWindowOpen(!isWindowActive);
    // eslint-disable-next-line
  }, [isVisible]);

  const handleClickToggle = (event) => {
    const id = event.target.getAttribute("id");
    if (id === "optionIcon") {
      setOptionSlideVisible(true);
    } else if (id === "hamburger_close_button_img") {
      setOptionSlideVisible(false);
    }
  };

  const handleClickAudioAndMusicToggle = (event) => {
    const id = event.target.getAttribute("id");
    if (id === "hamburger_audio_on_button") {
      setToggleAudio(false);
      // toggleMusic(true);
      toggleVoiceOver(false);
    } else if (id === "hamburger_audio_off_button") {
      setToggleAudio(true);
      toggleVoiceOver(true);
    } else if (id === "hamburger_music_on_button") {
      setMusicToggle(false);
      toggleMusic(false);
    } else if (id === "hamburger_music_off_button") {
      setMusicToggle(true);
      toggleMusic(true);
    }
  };

  const handleClick = (event) => {
    if (event.target.name === "music") {
      toggleMusic(!playMusic);
    } else {
      toggleVoiceOver(!playVoiceOver);
    }
  };
  return (
    <>
      <div className="audio_controls_container">
        <div onClick={handleClickToggle} className="optionIcon_img">
          <img id="optionIcon" src={optionIcon} alt="optionIcon" className="" />
        </div>
        {isOptionSlideVisible && (
          <div className="dropdown">
            <img
              src={optionSlideImage}
              alt="option slide image"
              className="optionSlide_img"
            />
            <div>
              <div onClick={handleClickToggle}>
                <img
                  id="hamburger_close_button_img"
                  src={hamburgerCloseButtonImage}
                  alt="hamburger close button"
                  className="hamburger_close_button_img"
                />
              </div>
              <div>
                <div
                  className="hamburger_audio_sub_container"
                  onClick={handleClickAudioAndMusicToggle}
                >
                  {toggleAudio && (
                    <div>
                      <img
                        id="hamburger_audio_on_button"
                        src={hamburgerAudioOnButtonImage}
                        alt="hamburger audio on button image"
                        className="hamburger_Audio_On_Button_img"
                      />
                    </div>
                  )}
                  {!toggleAudio && (
                    <div>
                      <img
                        id="hamburger_audio_off_button"
                        src={hamdburgerAudioOffButtonImage}
                        alt="hamburger audio off button image"
                        className="hamburger_Audio_Off_Button_img"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="hamburger_music_sub_container"
                  onClick={handleClickAudioAndMusicToggle}
                >
                  {musicToggle && (
                    <div>
                      <img
                        id="hamburger_music_on_button"
                        src={hamburgerMusicOnButtonImage}
                        alt="hamburger music on button image"
                        className="hamburger_music_on_button_img"
                      />
                    </div>
                  )}
                  {!musicToggle && (
                    <div>
                      <img
                        id="hamburger_music_off_button"
                        src={hamburgerMusicOffButtonImage}
                        alt="hamburger music off button image"
                        className="hamburger_music_off_button_img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    progress: state.progress,
    languages: state.intialize.languages,
    zones: state.intialize.zones,
    narrationData: state.shuffleData.narrationDataSet,
    audioData: state.shuffleData.narrationDataSet.narrationDataList,
    numeracyDetails: state.trackingUser.levelDetails,
    currentScreen: state.screenChange.screenID,
    levels: state.shuffleData.levels,
    playMusic: state.screenChange.playMusic,
    playVoiceOver: state.screenChange.playVoiceOver,
    isWindowActive: state.screenChange.isWindowOpen,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
    toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
    isWindowOpen: (isOpen) => dispatch(isWindowOpen(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(AudioControls);
