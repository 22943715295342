import axios from "axios";
import { BASE_URL, headers } from "../../utils/authAxios";
import { savePDF, savePDFFetchingData } from "./actions";
const platform = require("platform");

export const saveResponse = (data, success, failure) => {
	axios
		.post(`${BASE_URL}/api/game/response`, data, { headers: headers })
		.then((res) => {
			success(res.data);
		})
		.catch((err) => {
			failure(err);
		});
};

export const saveUserActivity = async (data) => {
	try {
		let result = await axios.post(`${BASE_URL}/api/save_game_track`, data, {
			headers,
		});
		return result;
	} catch (err) {
		console.log(err);
	}
};

export const saveAnalytics = (data, success, failure) => {
	const platformData = {
		name: platform.name,
		version: platform.version,
		product: platform.product,
		manufacturer: platform.manufacturer,
		layout: platform.layout,
		os: platform.os,
		description: platform.description,
	};
	axios
		.post(
			`${BASE_URL}/api/game/track`,
			{ platform: platformData, ...data },
			{ headers: headers }
		)
		.then((res) => {
			success();
		})
		.catch((err) => {
			failure(err);
		});
};

//PDF DOWNLOAD REPORT API
export const savePDFReport = (sessionId) => {
	const data = {
		gameSessionId: sessionId,
	};

	return async (dispatch) => {
		try {
			dispatch(savePDFFetchingData(true));
			const response = await axios.post(
				`${BASE_URL}/api/generate-profile-report`,
				data,
				{
					headers: headers,
				}
			);
			dispatch(savePDF(response.data));
			dispatch(savePDFFetchingData(false));
		} catch (error) {
			console.log(error);
			dispatch(savePDFFetchingData(false));
		}
	};
};
