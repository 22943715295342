import React, { useState, useEffect } from "react";
import textBoxLeftNextButton from "../../assets/images/App_intro_instructions_revamp/textbox_left_next_button.png";
import HotelText from "../../assets/images/anil_revamp_sets/HOTEL_TEXT.png";
import SandText from "../../assets/images/anil_revamp_sets/SAND_TEXTBOX.png";
import PlainText from "../../assets/images/anil_revamp_sets/HOTEL_TEXT_NO_NEXT.png";
import OnScreenInstruction from "../../assets/images/Museum_revamp/on_screen_instruction.png";

import "./style.scss";

const DialogBox = ({ dialogue, config }) => {
  const styles = [
    {
      bg: textBoxLeftNextButton,
    },
    {
      bg: HotelText,
    },
    {
      bg: SandText,
    },
    {
      bg: PlainText,
    },
    {
      bg: OnScreenInstruction,
    },
  ];
  const imagePath = styles[config.style].bg;
  const imageName = imagePath.split("/").pop(); // Get the last part of the split array
  const imageNameWithoutExtension = imageName.split(".")[0]; // Remove the extension

  console.log(imageNameWithoutExtension); // This will log the image name without the extension

  if (imageNameWithoutExtension === "on_screen_instruction") {
    return (
      <div className={`dialogue-box-on-screen-text`}>
        <img
          src={styles[config.style].bg}
          alt={config?.img?.alt}
          className="bg-img"
        />
        <div className="dialogue-on-screen-text">
          <p>{dialogue}</p>
        </div>
      </div>
    );
  } else if (imageNameWithoutExtension === "HOTEL_TEXT") {
    return (
      <div className={`dialogue-box-hotel-text`}>
        <img
          src={styles[config.style].bg}
          alt={config?.img?.alt}
          className="bg-img"
        />
        <div className="dialogue-hotel-text">
          <p>{dialogue}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`dialogue-box`}>
        <img
          src={styles[config.style].bg}
          alt={config?.img?.alt}
          className="bg-img"
        />
        <div className="dialogue">
          <p>{dialogue}</p>
        </div>
      </div>
    );
  }
};

export default DialogBox;
