import { QUESTION_SET_IDS } from "../../constants";
import { MARKET_MAX_REQUIRED_BOX_COUNT, MARKET_OPEN_ENDED_QUESTION } from "../types/index";
const initialState = {
  maxRequiredBoxCount: 0,
  marketOpenEndedQuestions: {
    questionSetId: QUESTION_SET_IDS.MARKET_SITUATION,
    gainedCoins: 0,
    question: [
      {
          questionId: "",
          count: 0,
          timeTakenMillis: 0,
          coin: 0
      },
    ]
  },
};
const marketReducer = (state = initialState, action) => {
  switch (action.type) {
    case MARKET_MAX_REQUIRED_BOX_COUNT:
      return { ...state, maxRequiredBoxCount: action.payload };
    case MARKET_OPEN_ENDED_QUESTION:
        return {...state, marketOpenEndedQuestions: action.payload }
    default:
      return state;
  }
};
export default marketReducer;