import React, { useState, useEffect, useRef } from "react";
// import top from '../../assets/images/insu/TOP_UI.png';
import background from "../../assets/images/anil_revamp_sets/HOTEL_BG.jpg";
import "./style.scss";
import { connect } from "react-redux";
import { setIndex, nextScreen } from "../../store/screentransistion/actions";
import HotelText from "../../assets/images/anil_revamp_sets/HOTEL_TEXT.png";
import CafeTopUiPanel from "../revampCommonComponent/cafeCommonComponent/cafeTopUiPanel";
import CafeBottomUiPanel from "../revampCommonComponent/cafeCommonComponent/cafeBottomUiPanel";
import DialogueBox from "../dialogueBox";
import playMusic from "../../utils/playMusic";
import { usePageVisibility } from "react-page-visibility";

//GOOGLE ANALYTICS
window.gtag("event", "HOTEL_INTRODUCTION", {
  event_category: "HOTEL_INTRODUCTION",
  event_label: "HOTEL_INTRODUCTION",
});

const AsherIntro = ({
  asher,
  narration,
  alt,
  setIndex,
  nextScreen,
  sceneDataList,
  currentIndex,
  sceneId,
  gender,
  audioClip,
  appIntroInstructionText,
  femaleNarrationAssetUrl,
  tapToContinue,
  narrationAssetUrl,
  playVoiceOver,
  imageAssetUrl,
  isWindowActive,
  //GAME REVAMP DEMO
  hotelReceptionistText,
}) => {
  const isVisible = usePageVisibility();

  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    setIsMounted(true);
    setInterval(() => {
      setIsMounted(false);
    }, 1000);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    let index = sceneDataList.findIndex(
      (element) => element.sceneID === "HOTEL_LIKE_OR_DISLIKE"
    );
    nextScreen(sceneId[index]);
    // nextScreen(sceneId[currentIndex]);
    // setIndex(currentIndex + 1);
  };
  const audioRef = useRef(null);
  useEffect(() => {
    audioRef.current = playMusic(`${femaleNarrationAssetUrl}${audioClip}`);
    if (!playVoiceOver || !isVisible) {
      audioRef.current.pause();
    }
    return () => audioRef.current.pause();
    // eslint-disable-next-line
  }, [playVoiceOver, isVisible]);

  return (
    <>
      <div className="hotel__container" onClick={handleClick}>
        <img src={background} alt="background" className="background" />
        {/* <CafeTopUiPanel /> */}
        <DialogueBox
          dialogue={hotelReceptionistText}
          config={{ style: 1, img: { alt: "hotel_welcome_text" } }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentIndex: state.screenChange.currentIndex,
    sceneId: state.shuffleData.sceneId,
    narrationAssetUrl: state.shuffleData.narrationDataSet.narrationAssetUrl,
    playVoiceOver: state.screenChange.playVoiceOver,
    imageAssetUrl: state.shuffleData.imageDataSet.imageAssetUrl,
    isWindowActive: state.screenChange.isWindowOpen,
    sceneDataList: state.shuffleData.sceneDataList,
    femaleNarrationAssetUrl:
      state.shuffleData.narrationDataSet.femaleNarrationAssetUrl,
    gender: state.screenChange.gender,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextScreen: (id) => dispatch(nextScreen(id)),
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsherIntro);
