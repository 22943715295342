import { LEVELS } from "../../constants";
import {
  SAVE_USER_REGISTRATION_INFO,
  SAVE_LEVEL_INFO,
  Is_USER_SUBMITTED_DATA,
  IS_LOADED,
  VEHICLE_SELECTION,
  SAVE_USER_SESSION_ID,
  SAVE_AVATAR_ID,
  ASK_BARGAIN,
  FIRST_BARGAIN_VALUE,
  SECOND_BARGAIN_VALUE,
  SAVE_PICTURE_GRAM,
  SAVE_SHOPPING_APP,
  SAVE_THREE_IMAGES,
  SAVE_NUMERACY,
  ADD_COINS,
  TWO_IMAGE_SELECTION_TIME,
  THREE_IMAGE_SELECTION_TIME,
  PICTUREGRAM_SELECTION_TIME,
  NUMERACY_SELECTION_TIME,
  TOTAL_TIME_TAKEN_IN_SEC,
  TOTAL_TIME_TAKEN_IN_MILLI_SEC,
  RESET_DATA,
  IS_HOTEL_MODULE_END,
  IS_CAFE_MODULE_END,
  IS_MUSEUM_MODULE_END,
  IS_BEACH_MODULE_END,
  IS_TOWER_ISLAND_MODULE_END,
  IS_MARKET_MODULE_END,
  OPEN_ENDED_QUESTION,
  SUBTRACT_COINS,
  GAINED_COINS_BARGAIN_SCENE,
  SAVE_PICTURE_GRAM_COINS,
  TWO_IMAGE_SELECTION_GAINED_COINS,
  THREE_IMAGE_SELECTION_GAINED_COINS,
  START_SCREEN,
  PDF_REPORT_DOWNLOAD,
  PDF_START_FETCHING,
  SAVE_NEW_THREE_RANKING_IMAGES,
  INSURANCE_GAME_HOTEL_SELECTION_TIME,
  INSURANCE_GAME_CAFE_SELECTION_TIME,
  INSURANCE_GAME_BEACH_SELECTION_TIME,
} from "../types";

const intialState = {
  user: {
    lang: "",
    zone: "",
    number: "",
    gameSessionId: "",
    coins: 0,
    totalTimeTakenSecs: 0,
    totalTimeTakenMillis: 0,
    // level1TotalTimeTakenMillis: 0,
    numeracyTotalTimeTakenMillis: 0,
    level4HotelTotalTimeTakenMillis: 0,
    level4CafeTotalTimeTakenMillis: 0,
    level4BeachTotalTimeTakenMillis: 0,
    // level3TotalTimeTakenMillis: 0,
    // level4TotalTimeTakenMillis: 0,
    level5TotalTimeTakenMillis: 0,
    avatar: {
      category: "",
      image_id: "",
      url: "",
    },
  },
  levels: {
    // level1: {
    //   levelType: "Level 1",
    //   gainedCoins: 0,
    //   questions: [],
    // },
    // level2: {
    //   levelType: "numeracy",
    //   gainedCoins: 0,
    //   questions: [],
    // },
    // level3: {
    //   levelType: "Level 3",
    //   gainedCoins: 0,
    //   questions: [],
    // },
    level4: [
      {
        levelType: "Level 4 Hotel",
        gainedCoins: 0,
        questions: [],
      },
      {
        levelType: "Level 4 Cafe",
        gainedCoins: 0,
        questions: [],
      },
      {
        levelType: "Level 4 Beach",
        gainedCoins: 0,
        questions: [],
      },
    ],
    level5: {
      levelType: LEVELS.TYPES.OPEN_ENDED_QNS,
      questions: [],
    },
  },
  purchaseList: null,
  moduleStatus: {
    isHotelModuleEnd: false,
    isCafeModuleEnd: false,
    isMuseumModuleEnd: false,
    isBeachModuleEnd: false,
    isTowerIslandModuleEnd: false,
    isMarketModuleEnd: false,
  },
  gainedCoins: {
    bargainScene: 0,
  },
};

const userTrackingReducer = (state = intialState, action) => {
  switch (action.type) {
    case SAVE_USER_REGISTRATION_INFO:
      return { ...state, user: { ...state.user, ...action.payload } };
    case SAVE_USER_SESSION_ID:
      return {
        ...state,
        user: { ...state.user, gameSessionId: action.payload },
      };
    case TOTAL_TIME_TAKEN_IN_SEC:
      return {
        ...state,
        user: { ...state.user, totalTimeTakenSecs: action.payload },
      };
    case TOTAL_TIME_TAKEN_IN_MILLI_SEC:
      return {
        ...state,
        user: { ...state.user, totalTimeTakenMillis: action.payload },
      };
    case ADD_COINS:
      return {
        ...state,
        user: { ...state.user, coins: state.user.coins + action.payload },
      };
    case SUBTRACT_COINS:
      return {
        ...state,
        user: { ...state.user, coins: state.user.coins - action.payload },
      };
    //insurance game beach new three ranking images
    case INSURANCE_GAME_BEACH_SELECTION_TIME:
      return {
        ...state,
        user: {
          ...state.user,
          level4BeachTotalTimeTakenMillis: action.payload,
        },
      };

    case TWO_IMAGE_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level3TotalTimeTakenMillis: action.payload },
      };
    //insurance game cafe new three ranking images
    case INSURANCE_GAME_CAFE_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level4CafeTotalTimeTakenMillis: action.payload },
      };
    case THREE_IMAGE_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level4TotalTimeTakenMillis: action.payload },
      };
    //insurance game hotel new three ranking images
    case INSURANCE_GAME_HOTEL_SELECTION_TIME:
      return {
        ...state,
        user: {
          ...state.user,
          level4HotelTotalTimeTakenMillis: action.payload,
        },
      };
    case PICTUREGRAM_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level1TotalTimeTakenMillis: action.payload },
      };
    case NUMERACY_SELECTION_TIME:
      return {
        ...state,
        user: {
          ...state.user,
          numeracyTotalTimeTakenMillis:
            state.user.numeracyTotalTimeTakenMillis + action.payload,
        },
      };
    case SAVE_AVATAR_ID:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: { ...state.user.avatar, category: action.payload },
        },
      };
    case SAVE_LEVEL_INFO:
      return { ...state, levelDetails: action.payload };
    case Is_USER_SUBMITTED_DATA:
      return { ...state, isSubmitted: action.payload };
    case IS_LOADED:
      return { ...state, isLoaded: action.payload };
    case VEHICLE_SELECTION:
      const index = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let ques = state.situations.questions;
      ques.splice(index, 1, action.payload);

      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...ques],
        },
      };
    case ASK_BARGAIN:
      const indexB = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bques = state.situations.questions;
      bques.splice(indexB, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bques],
        },
      };
    case FIRST_BARGAIN_VALUE:
      const indexBF = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bquesBF = state.situations.questions;
      bquesBF.splice(indexBF, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bquesBF],
        },
      };
    case SECOND_BARGAIN_VALUE:
      const indexL = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bquesL = state.situations.questions;
      bquesL.splice(indexL, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bquesL],
        },
      };
    case SAVE_PICTURE_GRAM:
      return {
        ...state,
        levels: {
          ...state.levels,
          level1: {
            ...state.levels.level1,
            questions: [...state.levels.level1.questions, action.payload],
          },
        },
      };
    case SAVE_PICTURE_GRAM_COINS:
      return {
        ...state,
        levels: {
          ...state.levels,
          level1: {
            ...state.levels.level1,
            gainedCoins: action.payload,
          },
        },
      };
    case SAVE_SHOPPING_APP:
      return {
        ...state,
        levels: {
          ...state.levels,
          level3: {
            ...state.levels.level3,
            questions: [...state.levels.level3.questions, action.payload],
          },
        },
      };
    //Insurance Game New Three ranking images
    case SAVE_NEW_THREE_RANKING_IMAGES:
      const { levelType, gainedCoins, questions } = action.payload;
      const updatedLevels = state.levels.level4.map((level) => {
        if (level.levelType === levelType) {
          return {
            ...level,
            gainedCoins: gainedCoins,
            questions: [...level.questions, ...questions],
          };
        }
        return level;
      });

      return {
        ...state,
        levels: {
          ...state.levels,
          level4: updatedLevels,
        },
      };
    case SAVE_THREE_IMAGES:
      return {
        ...state,
        levels: {
          ...state.levels,
          level4: {
            ...state.levels.level4,
            questions: [...state.levels.level4.questions, action.payload],
          },
        },
      };
    case SAVE_NUMERACY:
      return {
        ...state,
        levels: {
          ...state.levels,
          level2: {
            ...state.levels.level2,
            questions: [...state.levels.level2.questions, action.payload],
          },
        },
      };
    case OPEN_ENDED_QUESTION:
      return {
        ...state,
        levels: {
          ...state.levels,
          level5: {
            ...state.levels.level5,
            questions: [...state.levels.level5.questions, action.payload],
          },
        },
      };
    case RESET_DATA:
      return intialState;
    // Module ends
    case IS_HOTEL_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isHotelModuleEnd: action.payload,
        },
      };
    case IS_CAFE_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isCafeModuleEnd: action.payload,
        },
      };
    case IS_MUSEUM_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isMuseumModuleEnd: action.payload,
        },
      };
    case IS_BEACH_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isBeachModuleEnd: action.payload,
        },
      };
    case IS_TOWER_ISLAND_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isTowerIslandModuleEnd: action.payload,
        },
      };
    case IS_MARKET_MODULE_END:
      return {
        ...state,
        moduleStatus: {
          ...state.moduleStatus,
          isMarketModuleEnd: action.payload,
        },
      };
    case GAINED_COINS_BARGAIN_SCENE:
      return {
        ...state,
        gainedCoins: {
          ...state.gainedCoins,
          bargainScene: state.gainedCoins.bargainScene + action.payload,
        },
      };
    case TWO_IMAGE_SELECTION_GAINED_COINS:
      return {
        ...state,
        levels: {
          ...state.levels,
          level3: {
            ...state.levels.level3,
            gainedCoins: action.payload,
          },
        },
      };
    case THREE_IMAGE_SELECTION_GAINED_COINS:
      return {
        ...state,
        levels: {
          ...state.levels,
          level4: {
            ...state.levels.level4,
            gainedCoins: action.payload,
          },
        },
      };
    case START_SCREEN:
      return {
        ...state,
        startScreen: action.payload,
      };
    case PDF_REPORT_DOWNLOAD:
      return {
        ...state,
        pdfUrl: action.payload,
      };
    case PDF_START_FETCHING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default userTrackingReducer;
