import axios from "axios";

export let headers = {
  "Tenant-Access-Token": "ZWMyNmE4NzZiNjEwNTMwYzZhN2QwMWNiZWQ1ZDgyOTY=",
  "Tenant-Access-Key": "u29fl4Fh8AQ4Xg",
};

export const api = axios.create({
  baseURL: "https://immersive-game-india.confirmu.com",
  headers: {
    "Tenant-Access-Token": "ZWMyNmE4NzZiNjEwNTMwYzZhN2QwMWNiZWQ1ZDgyOTY=",
    "Tenant-Access-Key": "u29fl4Fh8AQ4Xg",
  },
});

export const BASE_URL = "https://immersive-game-india.confirmu.com";
