import { combineReducers } from "redux";
import intialize from "./intialize/reducer";
import shuffleData from "./shuffledata/reducer";
import trackingUser from "./trackinguser/reducer";
import screenChange from "./screentransistion/reducer";
import museum from "./museum/reducer";
import progressBar from "./timer/reducer";
import market from "./market/reducer";

const rootReducer = combineReducers({
  intialize,
  shuffleData,
  trackingUser,
  screenChange,
  museum,
  progressBar,
  market,
});

export default rootReducer;
