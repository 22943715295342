import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import BeachSand from "../../assets/images/anil_revamp_sets/back/BEACH_SAND_BG.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  openEndedQuestion,
  addCoins,
  subtractCoins,
} from "../../store/trackinguser/actions";
import SandText from "../../assets/images/anil_revamp_sets/SAND_TEXTBOX.png";
import { nextScreen, setIndex } from "../../store/screentransistion/actions";
import StopWatch from "../../utils/stopwatch";
import playMusic from "../../utils/playMusic";
import {
  BEACH_SHELL_COLLECTION,
  IMAGE_DATA_LIST,
  QUESTION_SET_IDS,
} from "../../constants";
import { usePageVisibility } from "react-page-visibility";

//GOOGLE ANALYTICS
window.gtag("event", "BEACH_SHELL_COLLECTION", {
  event_category: "BEACH_SHELL_COLLECTION",
  event_label: "BEACH_SHELL_COLLECTION",
});

let shellOpenEndedQuestions = {
  questionSetId: QUESTION_SET_IDS.BEACH_SHELL_COLLECTION_SITUATION,
  timeTakenMillis: 0,
  gainedCoins: 0,
  question: [],
};

// let gainedCoins = 0;

const ShellCollection = ({
  nextScreen,
  sceneId,
  ShellCollectionQuestion,
  sceneDataList,
  gender,
  femaleNarrationAssetUrl,
  narrationAssetUrl,
  playVoiceOver,
  openEndedQuestion,
  shellCollectionAudioMale,
  imageDataSet,
  addCoins,
  subtractCoins,
}) => {
  const isVisible = usePageVisibility();

  const audioRef = useRef(null);
  useEffect(() => {
    audioRef.current = playMusic(
      `${narrationAssetUrl}${shellCollectionAudioMale}`
    );

    if (!playVoiceOver || !isVisible) {
      audioRef.current.pause();
    }
    return () => audioRef.current.pause();
    // eslint-disable-next-line
  }, [
    playVoiceOver,
    gender,
    narrationAssetUrl,
    femaleNarrationAssetUrl,
    shellCollectionAudioMale,
    isVisible,
  ]);
  const [stopwatch] = useState(new StopWatch());
  useEffect(() => {
    // start the stopwatch when the component mounts
    stopwatch.start();
  }, [stopwatch]);
  const imageAssetUrl = imageDataSet.imageAssetUrl;
  const shellsImageDataListObject = imageDataSet.imageDataList.find(
    (item) => item.type === IMAGE_DATA_LIST.TYPES.SHELLS
  );
  const shellImages = shellsImageDataListObject
    ? shellsImageDataListObject.content
    : [];
  const [gainedCoins, setGainedCoins] = useState(0);

  useEffect(() => {
    shellOpenEndedQuestions.gainedCoins =
      parseInt(gainedCoins) <= 0 ? 0 : parseInt(gainedCoins);
  }, [gainedCoins]);

  const [pickedShells, setPickedShells] = useState({
    greenShells: 0,
    pinkShells: 0,
    blueShells: 0,
  });

  const [pickedShellClassName, setPickedShellClassName] = useState({
    shell_1: "",
    shell_2: "",
    shell_3: "",
    shell_4: "",
    shell_5: "",
    shell_6: "",
    Shell_7: "",
    shell_8: "",
    shell_9: "",
    shell_10: "",
    shell_11: "",
    shell_12: "",
  });
  // To trigger when we pick a shell.
  const pickShell = ($event) => {
    const id = $event.target.id;
    const shellColor = $event.target.getAttribute("data-shell-color");
    const pickedShellKey = "shell_" + id;
    setPickedShellClassName((prevState) => {
      return {
        ...prevState,
        [pickedShellKey]: `shell-clicked${id}`,
      };
    });

    switch (shellColor) {
      case BEACH_SHELL_COLLECTION.SHELL_COLORS.GREEN:
        setPickedShells((prevState) => {
          return {
            ...prevState,
            greenShells: prevState.greenShells + 1,
          };
        });
        break;
      case BEACH_SHELL_COLLECTION.SHELL_COLORS.PINK:
        setPickedShells((prevState) => {
          return {
            ...prevState,
            pinkShells: prevState.pinkShells + 1,
          };
        });
        break;
      case BEACH_SHELL_COLLECTION.SHELL_COLORS.BLUE:
        setPickedShells((prevState) => {
          return {
            ...prevState,
            blueShells: prevState.blueShells + 1,
          };
        });
        break;
      default:
        break;
    }
    setTimeout(function () {
      if (document.getElementById(id)) {
        document.getElementById(id).style.display = "none";
      }
    }, 1000);
  };

  const handleClick = (e) => {
    e.preventDefault();
    stopwatch.stop();

    // beach shell collection open ended questons.
    ShellCollectionQuestion.options.forEach((option) => {
      let count = 0;
      let coinVal = 0;
      switch (option.color) {
        case BEACH_SHELL_COLLECTION.SHELL_COLORS.GREEN:
          count = pickedShells.greenShells;
          break;
        case BEACH_SHELL_COLLECTION.SHELL_COLORS.PINK:
          count = pickedShells.pinkShells;
          break;
        case BEACH_SHELL_COLLECTION.SHELL_COLORS.BLUE:
          count = pickedShells.blueShells;
          break;
        default:
          break;
      }

      // Coins calculation
      if (count === parseInt(option.optionText)) {
        coinVal = parseInt(option.coin);
        addCoins(parseInt(option.coin));
        setGainedCoins((prevState) => prevState + parseInt(option.coin));
      } else {
        coinVal = -parseInt(option.coin);
        subtractCoins(parseInt(option.coin));
        setGainedCoins((prevState) => prevState - parseInt(option.coin));
      }

      shellOpenEndedQuestions.question.push({
        questionId: ShellCollectionQuestion.questionId,
        optionId: option.optionId,
        count: count,
        coin: coinVal && coinVal <= 0 ? 0 : coinVal,
      });
    });

    // shellOpenEndedQuestions.gainedCoins = gainedCoins.coins;
    shellOpenEndedQuestions.timeTakenMillis =
      stopwatch.getElapsedMilliseconds();
    openEndedQuestion(shellOpenEndedQuestions);

    if (
      !pickedShells.greenShells &&
      !pickedShells.pinkShells &&
      !pickedShells.blueShells
    ) {
      toast.warning("Please select at least one shell");
    } else {
      let index = sceneDataList.findIndex(
        (element) => element.sceneID === "BEACH_END"
      );
      nextScreen(sceneId[index]);
    }
  };

  const basketShells = () => {
    const shells = [];
    const colorShells = {
      greenShells: shellImages.filter((i) => i.color === "green"),
      pinkShells: shellImages.filter((i) => i.color === "pink"),
      blueShells: shellImages.filter((i) => i.color === "blue"),
    };
    for (let key in pickedShells) {
      for (let index = 1; index <= pickedShells[key]; index++) {
        const random = Math.floor(Math.random() * colorShells[key].length);
        shells.push({
          url: colorShells[key][random].url,
          style: {
            top: `${Math.floor(Math.random() * 80 + 1)}%`,
            left: `${Math.floor(Math.random() * 80 + 1)}%`,
          },
        });
      }
    }
    return shells;
  };

  return (
    <div className="beach__sand__container">
      <ToastContainer />
      <img src={BeachSand} alt="tower" className="sand_bg" />
      <div className="basket">
        {basketShells().map((i) => {
          return (
            <img
              src={
                `https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-insurance-game/game-assets/` +
                i.url
              }
              alt="Shells"
              className="shellImage"
              style={i.style}
            />
          );
        })}
      </div>
      {/* <CafeTopUiPanel /> */}
      <div className="sand_text" onClick={handleClick}>
        <img src={SandText} alt="text_sand" className="sand_text_img" />
        <div className="sand_wishes">
          <h3 className="wish_sand_text">
            {ShellCollectionQuestion?.questionText}
          </h3>
          <div className="sand_shell_numbers-container">
            {ShellCollectionQuestion?.options.map((item, index) => {
              return (
                <>
                  <div
                    className={`shell_collection${index + 1}`}
                    key={item.optionId}
                  >
                    <h2>{item.optionText}X</h2>
                  </div>
                  <div className={`shell_div${index + 1}`}>
                    <img
                      src={item.optionImageUrl}
                      alt="Shells"
                      className={`image_${index + 1}`}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {shellImages.length &&
        shellImages.map((shell, index) => {
          return (
            <div className={`shell_${index + 1}`}>
              <img
                id={index + 1}
                src={imageAssetUrl + shell.url}
                alt={`shell${index + 1}`}
                className={`sand_shell${index + 1} ${
                  pickedShellClassName
                    ? pickedShellClassName[`shell_${index + 1}`]
                    : ""
                }`}
                onClick={($event) => pickShell($event)}
                data-shell-color={shell.color}
              />
            </div>
          );
        })}
    </div>
  );
};
const mapStateToprops = (state) => {
  return {
    currentIndex: state.screenChange.currentIndex,
    sceneId: state.shuffleData.sceneId,
    narrationAssetUrl: state.shuffleData.narrationDataSet.narrationAssetUrl,
    playVoiceOver: state.screenChange.playVoiceOver,
    isWindowActive: state.screenChange.isWindowOpen,
    sceneDataList: state.shuffleData.sceneDataList,
    femaleNarrationAssetUrl:
      state.shuffleData.narrationDataSet.femaleNarrationAssetUrl,
    gender: state.screenChange.gender,
    imageDataSet: state.shuffleData.imageDataSet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextScreen: (id) => dispatch(nextScreen(id)),
    setIndex: (index) => dispatch(setIndex(index)),
    openEndedQuestion: (option) => dispatch(openEndedQuestion(option)),
    addCoins: (coins) => dispatch(addCoins(coins)),
    subtractCoins: (coins) => dispatch(subtractCoins(coins)),
  };
};

export default connect(mapStateToprops, mapDispatchToProps)(ShellCollection);
