import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { saveUserActivity } from "../../store/trackinguser/operations";

const WithTracking = ({ currentScreen, children }) => {
	const sceneDataList = useSelector((s) => s.shuffleData.sceneDataList);
	const gameSessionId = useSelector((s) => s.trackingUser.user.gameSessionId);
	useEffect(() => {
		const screen = sceneDataList.find((s) => s.sceneID == currentScreen);

		const doSaveUserActivity = (data) => {
			saveUserActivity(data).then((res) => {
				console.log(res);
			});
		};
		if (screen) {
			doSaveUserActivity({ ...screen, gameSessionId });
		}
	}, [currentScreen]);
	return <>{children}</>;
};

export default WithTracking;
