import {
  TIMER_START,
  MUSEUM_STAR,
  HOTEL_STAR,
  CAFE_STAR,
  BEACH_STAR,
  TOWER_STAR,
  MARKET_STAR,
  CAFE_TOP_UI_PANEL,
} from "../types";

export const startTimer = (data) => {
  return {
    type: TIMER_START,
    payload: data,
  };
};

export const hotelStar = (data) => {
  return {
    type: HOTEL_STAR,
    payload: data,
  };
};

export const cafeStar = (data) => {
  return {
    type: CAFE_STAR,
    payload: data,
  };
};

export const museumStar = (data) => {
  return {
    type: MUSEUM_STAR,
    payload: data,
  };
};

export const beachStar = (data) => {
  return {
    type: BEACH_STAR,
    payload: data,
  };
};

export const towerStar = (data) => {
  return {
    type: TOWER_STAR,
    payload: data,
  };
};

export const marketStar = (data) => {
  return {
    type: MARKET_STAR,
    payload: data,
  };
};
export const cafeTopUiPanel = (data) => {
  return {
    type: CAFE_TOP_UI_PANEL,
    payload: data,
  };
};
