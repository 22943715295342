import {
  SAVE_USER_REGISTRATION_INFO,
  SAVE_LEVEL_INFO,
  ADD_COINS,
  VEHICLE_SELECTION,
  SAVE_USER_SESSION_ID,
  SAVE_AVATAR_ID,
  ASK_BARGAIN,
  FIRST_BARGAIN_VALUE,
  SECOND_BARGAIN_VALUE,
  SAVE_PICTURE_GRAM,
  SAVE_SHOPPING_APP,
  SAVE_THREE_IMAGES,
  SAVE_NUMERACY,
  TWO_IMAGE_SELECTION_TIME,
  PICTUREGRAM_SELECTION_TIME,
  THREE_IMAGE_SELECTION_TIME,
  NUMERACY_SELECTION_TIME,
  TOTAL_TIME_TAKEN_IN_SEC,
  TOTAL_TIME_TAKEN_IN_MILLI_SEC,
  SAVE_RESPONSE,
  RESET_DATA,
  IS_HOTEL_MODULE_END,
  IS_CAFE_MODULE_END,
  IS_MUSEUM_MODULE_END,
  IS_BEACH_MODULE_END,
  IS_TOWER_ISLAND_MODULE_END,
  IS_MARKET_MODULE_END,
  OPEN_ENDED_QUESTION,
  SUBTRACT_COINS,
  GAINED_COINS_BARGAIN_SCENE,
  SAVE_PICTURE_GRAM_COINS,
  TWO_IMAGE_SELECTION_GAINED_COINS,
  THREE_IMAGE_SELECTION_GAINED_COINS,
  START_SCREEN,
  PDF_REPORT_DOWNLOAD,
  PDF_START_FETCHING,
  SAVE_NEW_THREE_RANKING_IMAGES,
  INSURANCE_GAME_HOTEL_SELECTION_TIME,
  INSURANCE_GAME_CAFE_SELECTION_TIME,
  INSURANCE_GAME_BEACH_SELECTION_TIME,
} from "../types";

export const saveUserDetails = (details) => {
  return {
    type: SAVE_USER_REGISTRATION_INFO,
    payload: details,
  };
};

export const saveUserSessionId = (id) => {
  return {
    type: SAVE_USER_SESSION_ID,
    payload: id,
  };
};

export const saveAvatarId = (id) => {
  return {
    type: SAVE_AVATAR_ID,
    payload: id,
  };
};

export const askBargain = (bargain) => {
  return {
    type: ASK_BARGAIN,
    payload: bargain,
  };
};

export const firstBargain = (value) => {
  return {
    type: FIRST_BARGAIN_VALUE,
    payload: value,
  };
};

export const secondBargain = (value) => {
  return {
    type: SECOND_BARGAIN_VALUE,
    payload: value,
  };
};

export const saveShoppingApp = (cart) => {
  return {
    type: SAVE_SHOPPING_APP,
    payload: cart,
  };
};

export const savePictureGram = (pict) => {
  return {
    type: SAVE_PICTURE_GRAM,
    payload: pict,
  };
};
export const savePictureGramCoins = (coins) => {
  return {
    type: SAVE_PICTURE_GRAM_COINS,
    payload: coins,
  };
};
export const saveThreeImages = (obj) => {
  return {
    type: SAVE_THREE_IMAGES,
    payload: obj,
  };
};

export const saveNumeracy = (obj) => {
  return {
    type: SAVE_NUMERACY,
    payload: obj,
  };
};

export const saveLevels = (details) => {
  return {
    type: SAVE_LEVEL_INFO,
    payload: details,
  };
};

export const addCoins = (coins) => {
  return {
    type: ADD_COINS,
    payload: coins,
  };
};

export const subtractCoins = (coins) => {
  return {
    type: SUBTRACT_COINS,
    payload: coins,
  };
};

export const twoImageTotalTime = (time) => {
  return {
    type: TWO_IMAGE_SELECTION_TIME,
    payload: time,
  };
};

export const picturegramTotalTime = (time) => {
  return {
    type: PICTUREGRAM_SELECTION_TIME,
    payload: time,
  };
};

export const threeImagesTotalTime = (time) => {
  return {
    type: THREE_IMAGE_SELECTION_TIME,
    payload: time,
  };
};

export const numeracyTotalTime = (time) => {
  return {
    type: NUMERACY_SELECTION_TIME,
    payload: time,
  };
};

export const totalTimeTakenInSec = (time) => {
  return {
    type: TOTAL_TIME_TAKEN_IN_SEC,
    payload: time,
  };
};

export const totalTimeTakenInMilliSec = (time) => {
  return {
    type: TOTAL_TIME_TAKEN_IN_MILLI_SEC,
    payload: time,
  };
};

export const saveResponse = (data) => {
  return {
    type: SAVE_RESPONSE,
    payload: data,
  };
};

export const resetState = () => {
  return {
    type: RESET_DATA,
  };
};

// export const isUserSubmittedData = (isSubmitted) => {
//   return {
//     type: Is_USER_SUBMITTED_DATA,
//     payload: isSubmitted,
//   };
// };

// export const isLoaded = (isLoaded) => {
//   return {
//     type: ADD_COINS,
//     payload: isLoaded,
//   };
// };

export const vehicleType = (vehicle) => {
  return {
    type: VEHICLE_SELECTION,
    payload: vehicle,
  };
};

// Redux actions to identify each module end or not.
export const isHotelModuleEnd = (state) => {
  return {
    type: IS_HOTEL_MODULE_END,
    payload: state,
  };
};
export const isCafeModuleEnd = (state) => {
  return {
    type: IS_CAFE_MODULE_END,
    payload: state,
  };
};
export const isMuseumModuleEnd = (state) => {
  return {
    type: IS_MUSEUM_MODULE_END,
    payload: state,
  };
};
export const isBeachModuleEnd = (state) => {
  return {
    type: IS_BEACH_MODULE_END,
    payload: state,
  };
};
export const isTowerIslandModuleEnd = (state) => {
  return {
    type: IS_TOWER_ISLAND_MODULE_END,
    payload: state,
  };
};
export const isMarketModuleEnd = (state) => {
  return {
    type: IS_MARKET_MODULE_END,
    payload: state,
  };
};

export const openEndedQuestion = (option) => {
  return {
    type: OPEN_ENDED_QUESTION,
    payload: option,
  };
};

export const gainedCoinsBargainScene = (coins) => {
  return {
    type: GAINED_COINS_BARGAIN_SCENE,
    payload: coins,
  };
};

export const saveTwoImageSelectionGainedCoins = (coins) => {
  return {
    type: TWO_IMAGE_SELECTION_GAINED_COINS,
    payload: coins,
  };
};

export const saveThreeImageSelectionGainedCoins = (coins) => {
  return {
    type: THREE_IMAGE_SELECTION_GAINED_COINS,
    payload: coins,
  };
};

//START SCREEN
export const startScreen = (data) => {
  return {
    type: START_SCREEN,
    payload: data,
  };
};

//PDF DOWNLOAD REPORT
export const savePDFFetchingData = (data) => {
  return {
    type: PDF_START_FETCHING,
    payload: data,
  };
};
export const savePDF = (data) => {
  return {
    type: PDF_REPORT_DOWNLOAD,
    payload: data,
  };
};

//Insurance Game new three ranking images
export const saveNewThreeRankingImages = (obj) => {
  return {
    type: SAVE_NEW_THREE_RANKING_IMAGES,
    payload: obj,
  };
};
//Insurance Game Hotel new three ranking images
export const hotelNewThreeRankingTotalTime = (time) => {
  return {
    type: INSURANCE_GAME_HOTEL_SELECTION_TIME,
    payload: time,
  };
};
//Insurance Game cafe new three ranking images
export const cafeNewThreeRankingTotalTime = (time) => {
  return {
    type: INSURANCE_GAME_CAFE_SELECTION_TIME,
    payload: time,
  };
};
//Insurance Game beach new three ranking images
export const beachNewThreeRankingTotalTime = (time) => {
  return {
    type: INSURANCE_GAME_BEACH_SELECTION_TIME,
    payload: time,
  };
};
