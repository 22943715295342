// Museum Module
const BARGAIN_SCENE_COINS = {
  GREATER_THAN_BUDGET: 10, // Subtract 10 coins
  IF_BARGAIN: 10, // Add 10  coins
  FIRST_BARGAIN: 10, // Add 10 coins
  SECOND_BARGAIN: 10, // Add 10 coins
};

const BARGAIN_QUESTION_IDS = {
  SQ_PURCHASE_AMOUNT: "SQ_PURCHASE_AMOUNT",
  SQ_BARGAIN: "SQ_BARGAIN",
  SQ_BARGAIN_1: "SQ_BARGAIN_1",
  SQ_BARGAIN_2: "SQ_BARGAIN_2",
};

// Market Module
const MARKET_SCENE = {
  NOT_EQUAL_MAX_REQUIRED_COUNT: 10, // Subtract 10 coins
  EQUAL_MAX_REQUIRED_COUNT: 30, // Add 30 coins
};

// Question Sets
const QUESTION_SET_IDS = {
  WAKEUP_SITUATION: "wakeup-situation",
  MENU_ORDERING_SITUATION: "menu-ordering-situation",
  BARGAIN_SITUATION: "bargain-situation",
  BEACH_SHELL_COLLECTION_SITUATION: "beach-shell-collecting-situation",
  MARKET_SITUATION: "market-situation",
  BEACH_STRESSFUL_SITUATION: "beach-stressful-situation",
};

// LevelTypes
const LEVELS = {
  TYPES: {
    OPEN_ENDED_QNS: "open-ended-questions",
  },
};

// Beach shell collection scene.
const BEACH_SHELL_COLLECTION = {
  COINS: {
    COIN: 10,
    GAINED_COINS: 30,
  },
  SHELL_COLORS: {
    GREEN: "green",
    PINK: "pink",
    BLUE: "blue",
  },
};

const IMAGE_DATA_LIST = {
  TYPES: {
    SHELLS: "SHELLS",
  },
};

export {
  BARGAIN_SCENE_COINS,
  MARKET_SCENE,
  BARGAIN_QUESTION_IDS,
  QUESTION_SET_IDS,
  BEACH_SHELL_COLLECTION,
  IMAGE_DATA_LIST,
  LEVELS,
};
