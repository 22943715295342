import {
  TIMER_START,
  MUSEUM_STAR,
  HOTEL_STAR,
  CAFE_STAR,
  BEACH_STAR,
  TOWER_STAR,
  MARKET_STAR,
  CAFE_TOP_UI_PANEL,
} from "../types/index";
const initialState = {
  isLoaded: false,
  timer: false,
  museumStar: false,
  cafeTopUiPanel: false,
};
const progressBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMER_START:
      return { ...state, isLoaded: true, timer: action.payload };
    case HOTEL_STAR:
      return { ...state, isLoaded: true, hotelStar: action.payload };
    case CAFE_STAR:
      return { ...state, isLoaded: true, cafeStar: action.payload };
    case MUSEUM_STAR:
      return { ...state, isLoaded: true, museumStar: action.payload };
    case BEACH_STAR:
      return { ...state, isLoaded: true, beachStar: action.payload };
    case TOWER_STAR:
      return { ...state, isLoaded: true, towerStar: action.payload };
    case MARKET_STAR:
      return { ...state, isLoaded: true, marketStar: action.payload };
    case CAFE_TOP_UI_PANEL:
      return { ...state, isLoaded: true, cafeTopUiPanel: action.payload };
    default:
      return state;
  }
};
export default progressBarReducer;
