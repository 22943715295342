import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import "../utils/gameLayout.scss";
import RotateImg from "../assets/images/rotate-mobile.png";
import CUlogo from "../assets/images/confirmu-logo.png";
import { connect } from "react-redux";
import GameStart from "./../components/gamestart/index.js";
import SplashScreen from "../components/splashscreen/index.js";
import Registration from "./../components/registration/index.js";

import cx from "classnames";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CurrentComponent from "../components/currentcomponent/index.js";
import AsherIntro from "../components/asherintro/index.js";
import CafeBottomUiPanel from "../components/revampCommonComponent/cafeCommonComponent/cafeBottomUiPanel";
import { startTimer } from "../store/timer/action";
import CafeTopUiPanel from "../components/revampCommonComponent/cafeCommonComponent/cafeTopUiPanel";

const isPortrait = () => {
  var mql = window.matchMedia("(orientation: landscape)");
  return mql.matches;
};

let _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

const CuLayout = (props) => {
  const [desktop, setDesktop] = useState(false);
  const [page, setPage] = useState("GameStart");
  const [submittedMobile, setData] = useState("");
  const [portraitMode, setMode] = useState(isPortrait());

  useEffect(() => {
    setDesktop(!isMobile);
    window.addEventListener(
      "resize",
      function () {
        setMode(isPortrait());
      },
      false
    );
  }, []);

  const fullScreen = () => {
    let body = document.getElementsByTagName("body")[0];
    if (body.requestFullscreen) return body.requestFullscreen();
    else if (body.webkitRequestFullScreen)
      return body.webkitRequestFullScreen();
  };

  const components = {
    GameStart,
    SplashScreen,
    Registration,
    CurrentComponent,
    AsherIntro,
  };

  const gotToPage = (page, mobile) => {
    if (_iOSDevice) {
      setOtherData(page, mobile);
      if (page === "GameStart" && !desktop) {
        fullScreen();
      }
      return;
    }
    if (page === "GameStart") {
      fullScreen()
        .then(() => {
          window.screen
            .lockOrientation("landscape")
            .then(function () {
              setOtherData(page, mobile);
            })
            .catch(function (error) {
              //no force rotate support
              setOtherData(page, mobile);
            });
        })
        .catch(() => {
          //no fullscreen support
          setOtherData(page, mobile);
        });
    } else {
      setOtherData(page, mobile);
    }
  };
  const setOtherData = (page, mobile) => {
    setPage(page);
    if (mobile) setData(mobile);
  };

  const render = (page) => {
    let SpecificPage = components[page];
    return (
      <div className={cx("w-h-100", { portraitMode: portraitMode })}>
        {" "}
        {portraitMode && page === "GameStart" && (
          <div className="rotate_screen">
            <div>
              {/* <h2>Please rotate device to Landscape mode to continue</h2> */}
              <img src={RotateImg}></img>
              <img src={CUlogo}></img>
            </div>
          </div>
        )}
        {props.cafeTopUiPanel === true && <CafeTopUiPanel />}
        <SpecificPage
          history={props.history}
          gotToPage={gotToPage}
          mobile={submittedMobile}
        />
        {props.startTimer && (
          <CafeBottomUiPanel startTimer={props.startTimer} />
        )}
      </div>
    );
  };

  return <div className={classnames("main")}>{render(page)}</div>;
};
const mapStateToProps = (state) => {
  return {
    startTimer: state.progressBar.timer,
    cafeTopUiPanel: state.progressBar.cafeTopUiPanel,
  };
};

const mapDisPatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDisPatchToProps)(CuLayout);
