import { lazy } from "react";
import { CURRENT_SCREEN_ID, INTIAl_LOADING } from "../store/types";
import shellcollection from "../components/shellcollection";
import RevampFeedback from "../components/revampFeedback";
import WithTracking from "../components/WithTracking";

const Registration = lazy(() => import("../components/registration"));
const IntialLoading = lazy(() => import("../components/intialloading"));
const IntroScene = lazy(() => import("../components/introscreen"));
const AsherIntro = lazy(() => import("../components/asherintro"));
const HelpScreen = lazy(() => import("../components/help"));
const ShoppingApp = lazy(() => import("../components/levelone"));
const HotelNumeracy = lazy(() => import("../components/hotelNumeracy"));
const HomeNumeracy = lazy(() => import("../components/morningwalk"));
const VehicleSelection = lazy(() => import("../components/vehicleselection"));
const FuelStation = lazy(() => import("../components/fuelstation"));
const CafeEnter = lazy(() => import("../components/cafe"));
const Cab = lazy(() => import("../components/carseating"));
const Bus = lazy(() => import("../components/busseating"));
const PictureGram = lazy(() => import("../components/picturegram"));
const ThreeImage = lazy(() => import("../components/paintings"));
const StoreIintro = lazy(() => import("../components/storeintro"));
const Bargain = lazy(() => import("../components/storebargain"));
const CafeConversations = lazy(() => import("../components/cafeconversations"));
const CafeTipPercentage = lazy(() => import("../components/cafetippercentage"));
const CafeTipQuestion = lazy(() => import("../components/cafetipquestion"));
const GroceryShop = lazy(() => import("../components/groceryshop"));
const FeedBack = lazy(() => import("../components/feedback"));
const ReturnHome = lazy(() => import("../components/finalscreen"));
const EndGame = lazy(() => import("../components/endgame"));
//new components
const BargainAmount = lazy(() => import("../components/bargainAmount"));
const FinalBargaining = lazy(() => import("../components/finalBargaining"));
const PurchasedItem = lazy(() => import("../components/purchasedItem"));
//MARKET new components
const MarketIntro = lazy(() => import("../components/marketIntro"));
const MarketSweetSelection = lazy(() =>
	import("../components/marketSweetCollection")
);
const MarketThankYouScene = lazy(() =>
	import("../components/marketThankYouScene")
);
const AppIntro = lazy(() => import("../components/appIntro"));
const GifStar = lazy(() =>
	import("../components/revampCommonComponent/gifStar")
);
const AppIntroInstructions = lazy(() =>
	import("../components/appIntroInstructions")
);
const AppIntroContentScenes = lazy(() =>
	import("../components/appIntroContentScenes")
);
const CongratsScene = lazy(() => import("../components/congrats"));
//Anil
const AvatarChoose = lazy(() => import("../components/avatarchoose"));
const BeachTower = lazy(() => import("../components/beachtowerintro"));
const BeachShellCollection = lazy(() =>
	import("../components/shellcollection")
);

const BeachThanks = lazy(() => import("../components/beachend"));
const GameRevampFeedback = lazy(() =>
	import("../components/gameRevampFeedback")
);
const GifStarTwo = lazy(() => import("../components/gifStarTwo"));
const IntroHotel = lazy(() => import("../components/introhotel"));
const IntroCafe = lazy(() => import("../components/introcafe"));
const IntroMuseum = lazy(() => import("../components/intromuseum"));
const IntroBeach = lazy(() => import("../components/introbeach"));
const IntroTower = lazy(() => import("../components/introtower"));
const IntroMarket = lazy(() => import("../components/intromarket"));
//star
const HotelStar = lazy(() =>
	import("../components/star_revamp_component/hotelStar")
);
const CafeStar = lazy(() =>
	import("../components/star_revamp_component/cafeStar")
);
const BeachStar = lazy(() =>
	import("../components/star_revamp_component/beachStar")
);
const TowerStar = lazy(() =>
	import("../components/star_revamp_component/towerstar")
);
const MarketStar = lazy(() =>
	import("../components/star_revamp_component/marketStar")
);
const MuseumStar = lazy(() =>
	import("../components/star_revamp_component/starMuseum")
);
//new scene and component added
const BeachStressful = lazy(() => import("../components/beachStressfulScene"));
const HomeSleepRoutine = lazy(() => import("../components/homeSleepRoutine"));
const StartScreen = lazy(() => import("../components/startScreen"));

const getLevel = (levels, id) => levels.find((level) => level.levelType === id);
const getQuestion = (questions, id) =>
	questions.find((question) => question.questionSetId === id);
const gettipQuestion = (questions, id) =>
	questions.find((question) => question.questionId === id);
const getNarration = (messages, id) =>
	messages.find((message) => message.id === id);

const getImage = (images, id) => images.find((image) => image.id === id);

let walletQuestion,
	//audio narration
	bargainSorryIcannotAudio,
	beachAvatarAudio,
	okayAudioClip,
	thankingAudioClip,
	beachAvatarFirstAudio,
	textCongratulationsAudio,
	marketLetPackAudioMale,
	marketSweetCollectionAudio,
	beachAvatarThinkTwoTower,
	beachAvatarThinkOneTowerAudio,
	beachAvatarThinkOneAudio,
	bargainShopkeeperThankingTextAudioClip,
	bargainDeclinedNarrationAudio,
	bargainShopkeeperTwoNarration,
	bargainAcceptNarration,
	bargainShopkeeperOneNarration,
	bargainPurchaseInstructionNarration,
	cafeFriendConversationTwoNarration,
	cafeFriendConversationOneNarration,
	cafeWaiterOrderAcceptNarration,
	cafeWaiterOrderTakingNarration,
	hotelLikeAndDislikeTextNarration,
	gameStartNarration,
	explainMenuButtonNarration,
	explainProgressBarNarration,
	explainTimerNarration,
	explainTokenNarration,
	textAvatarChooseNarration,
	hotelReceptionistNarration,
	navigationTextNarration,
	gameIntroNarration,
	museumQuestion,
	//MUSEUM
	cafeMenuItems,
	//Cafe
	marketSweetItems,
	//market
	wakeUpHours,
	//Hotel
	ShellCollectionQuestion,
	//Tower
	//Beach
	textStart,
	beachAvatarExploreBeachText,
	bikePetrol,
	busCharge,
	groceryQuestion,
	tipQuestion,
	carPetrol,
	cabCharge,
	introNarration,
	asherNarration,
	avatarSelectionNarration,
	vehicleSelectionNarration,
	vehicleBike,
	vehicleCar,
	vehicleTaxi,
	vehicleBus,
	cafeEnterNarration,
	avatarQuestion,
	shopKeeperReply,
	offerBargain,
	bargainQuote,
	packedText,
	cutOffPrice,
	bargainQuote2,
	finalPrice,
	checkPicturegram,
	paintingsAdviceRequest,
	paintingsAdvice,
	askBill,
	responseBill,
	questionPercentage5,
	questionPercentage10,
	questionPercentage15,
	groceryAvatar,
	tokensText,
	progressText,
	timeText,
	saleAnnouncement,
	tapToContinue,
	finalMessage,
	finalButton,
	finalTitle,
	asherStoreEntry,
	labelBuy,
	labelLeave,
	labelQuote,
	labelYes,
	labelNo,
	labelAskFor,
	finalAsherText,
	bargainNarrations,
	percentageNumeracy,
	asherImage,
	appIntroInstructionText,
	nextText,
	avatarChoosetext,
	explainTokenText,
	explainTimerText,
	explainProgressText,
	explainMenuButtonText,
	gameStartText,
	navigationText,
	hotelReceptionistText,
	cafeWaiterOrdertakingText,
	cafeWaiterOrderAcceptText,
	cafeFriendConversationOneText,
	cafeFriendConversationTwoText,
	bargainPurchaseInstructionText,
	bargainShopkeeperOneText,
	textBargain,
	textAccept,
	bargainAcceptText,
	bargainShopkeeperThankingText,
	bargainShopKeeperTwoText,
	beachAvatarExploreText,
	beachAvatarThinkTwoText,
	marketShopkeeperOneText,
	bargainShopkeeperThreetext,
	textAskText,
	bargainShopkeeperFourText,
	textDecline,
	bargainDeclineText,
	textCafePlaceOrderText,
	marketShoppingInstructionsText,
	marketShopkeeperPackText,
	feedbackCongratulationText,
	feedbackScoreText,
	feedbackDownloadReport,
	textDownload,
	textDownloadClick,
	feedbackCheckProductRedirectUrlText,
	textCheckProductClick,
	textCheckProduct,
	feedbackfinalText,
	feedbackNQText,
	feedbackEQText,
	feedbackMQText,
	textPhysical,
	textPsychological,
	textSocial,
	beachStressfulQuestion,
	textSpa,
	textCasino,
	textFood,
	textShopping,
	textGym,
	textHoliday,
	textClubNight,
	textConcert,
	textOnlineGame,
	textRankingOne,
	textRankingTwo,
	homeSleepRoutineQuestion;

const setScreens = (
	currentScreen,
	setComponent,
	component,
	messages,
	levels,
	images,
	isFeedbackRequired
) => {
	const numeracyQuestions = getLevel(levels, "numeracy");
	const levelOne = getLevel(levels, "Level 4 Hotel");
	const levelThree = getLevel(levels, "Level 4 Beach");
	const levelFour = getLevel(levels, "Level 4 Cafe");
	const openEndedQuestions = getLevel(levels, "open-ended-questions");

	if (messages) {
		bargainSorryIcannotAudio = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_FOUR"
		);
		thankingAudioClip = getNarration(messages, "BARGAIN_SHOPKEEPER_THANKING");
		okayAudioClip = getNarration(messages, "BARGAIN_SHOPKEEPER_THREE");
		beachAvatarFirstAudio = getNarration(messages, "BEACH_AVATAR");
		textCongratulationsAudio = getNarration(messages, "TEXT_CONGRATULATIONS");
		marketLetPackAudioMale = getNarration(messages, "MARKET_SHOPKEEPER_PACK");
		marketSweetCollectionAudio = getNarration(
			messages,
			"MARKET_SHOPPING_INSTRUCTIONS"
		);
		beachAvatarThinkTwoTower = getNarration(messages, "BEACH_AVATAR_THINK_TWO");
		beachAvatarThinkOneTowerAudio = getNarration(
			messages,
			"BEACH_AVATAR_THINK_ONE"
		);
		beachAvatarThinkOneAudio = getNarration(messages, "BEACH_AVATAR_THINK_ONE");
		beachAvatarAudio = getNarration(messages, "BEACH_AVATAR");
		bargainShopkeeperThankingTextAudioClip = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_THANKING"
		);
		bargainDeclinedNarrationAudio = getNarration(messages, "BARGAIN_DECLINED");
		bargainShopkeeperTwoNarration = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_TWO"
		);
		bargainAcceptNarration = getNarration(messages, "BARGAIN_ACCEPT");
		bargainShopkeeperOneNarration = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_ONE"
		);
		bargainPurchaseInstructionNarration = getNarration(
			messages,
			"BARGAIN_PURCHASE_INSTRUCTION"
		);
		cafeFriendConversationTwoNarration = getNarration(
			messages,
			"CAFE_FRIEND_CONVERSATION_TWO"
		);
		cafeFriendConversationOneNarration = getNarration(
			messages,
			"CAFE_FRIEND_CONVERSATION_ONE"
		);
		cafeWaiterOrderAcceptNarration = getNarration(
			messages,
			"CAFE_WAITER_ORDER_ACCEPT"
		);
		cafeWaiterOrderTakingNarration = getNarration(
			messages,
			"CAFE_WAITER_ORDER_TAKING"
		);
		hotelLikeAndDislikeTextNarration = getNarration(
			messages,
			"HOTEL_LIKE_DISLIKE_TEXT"
		);
		gameStartNarration = getNarration(messages, "GAME_START");
		explainMenuButtonNarration = getNarration(messages, "EXPLAIN_MENU_BUTTON");
		explainProgressBarNarration = getNarration(
			messages,
			"EXPLAIN_PROGRESS_BAR"
		);
		explainTimerNarration = getNarration(messages, "EXPLAIN_TIMER");
		explainTokenNarration = getNarration(messages, "EXPLAIN_TOKEN");
		textAvatarChooseNarration = getNarration(messages, "TEXT_AVATAR_CHOOSE");
		gameIntroNarration = getNarration(messages, "GAME_INTRO");
		navigationTextNarration = getNarration(messages, "NAVIGATION_TEXT");
		hotelReceptionistNarration = getNarration(messages, "HOTEL_RECEPTIONIST");
		//audio
		beachAvatarExploreBeachText = getNarration(messages, "BEACH_AVATAR");
		introNarration = getNarration(messages, "TEXT_BODYLESS_INTRO_1");
		asherNarration = getNarration(messages, "TEXT_ASHER_INTRO_1");
		avatarSelectionNarration = getNarration(messages, "ASHER_PLAYMOJI");
		vehicleSelectionNarration = getNarration(messages, "ASHER_SELECT_TRAVEL");
		vehicleBike = getNarration(messages, "LABEL_BIKE");
		vehicleCar = getNarration(messages, "LABEL_CAR");
		vehicleTaxi = getNarration(messages, "LABEL_TAXI");
		vehicleBus = getNarration(messages, "LABEL_BUS");
		cafeEnterNarration = getNarration(messages, "ASHER_CAFE");
		avatarQuestion = getNarration(messages, "BARGAIN_AVATAR_1");
		shopKeeperReply = getNarration(messages, "BARGAIN_SHOPKEEPER_1");
		offerBargain = getNarration(messages, "BARGAIN_ASHER");
		bargainQuote = getNarration(messages, "BARGAIN_AVATAR_2");
		packedText = getNarration(messages, "BARGAIN_SHOPKEEPER_2");
		cutOffPrice = getNarration(messages, "BARGAIN_SHOPKEEPER_3");
		bargainQuote2 = getNarration(messages, "BARGAIN_AVATAR_3");
		finalPrice = getNarration(messages, "BARGAIN_SHOPKEEPER_4");
		checkPicturegram = getNarration(messages, "TEXT_PICTUREGRAM");
		paintingsAdviceRequest = getNarration(messages, "AVATAR_RAHUL");
		paintingsAdvice = getNarration(messages, "RAHUL_AVATAR");
		askBill = getNarration(messages, "CONVERSATION_AVATAR_3");
		responseBill = getNarration(messages, "CONVERSATION_NPC_4");
		groceryAvatar = getNarration(messages, "TEXT_GROCERY");
		tokensText = getNarration(messages, "TEXT_ASHER_TUTORIAL_1");
		progressText = getNarration(messages, "TEXT_ASHER_TUTORIAL_2");
		timeText = getNarration(messages, "TEXT_ASHER_TUTORIAL_3");
		saleAnnouncement = getNarration(messages, "ASHER_SHOP_NOW");
		tapToContinue = getNarration(messages, "LABEL_TAP_TO_CONTINUE");
		finalMessage = getNarration(messages, "FINAL_SCREEN_MESSAGE");
		finalButton = getNarration(messages, "FINAL_SCREEN_BUTTON");
		finalTitle = getNarration(messages, "FEEDBACK_TITLE_TWO");
		finalAsherText = getNarration(messages, "ASHER_SUBMIT");
		asherStoreEntry = getNarration(messages, "ASHER_SHOPPING");
		labelBuy = getNarration(messages, "LABEL_BUY");
		labelLeave = getNarration(messages, "LABEL_LEAVE");
		labelQuote = getNarration(messages, "LABEL_SLIDE_TO_QUOTE");
		labelYes = getNarration(messages, "LABEL_YES");
		labelNo = getNarration(messages, "LABEL_NO");
		labelAskFor = getNarration(messages, "LABEL_ASK_FOR");
		//GAME REVAMP DEMO
		textStart = getNarration(messages, "TEXT_START");
		avatarChoosetext = getNarration(messages, "TEXT_AVATAR_CHOOSE");
		nextText = getNarration(messages, "TEXT_NEXT");
		appIntroInstructionText = getNarration(messages, "GAME_INTRO");
		explainTokenText = getNarration(messages, "EXPLAIN_TOKEN");
		explainTimerText = getNarration(messages, "EXPLAIN_TIMER");
		explainProgressText = getNarration(messages, "EXPLAIN_PROGRESS_BAR");
		explainMenuButtonText = getNarration(messages, "EXPLAIN_MENU_BUTTON");
		gameStartText = getNarration(messages, "GAME_START");
		navigationText = getNarration(messages, "NAVIGATION_TEXT");
		hotelReceptionistText = getNarration(messages, "HOTEL_RECEPTIONIST");
		cafeWaiterOrdertakingText = getNarration(
			messages,
			"CAFE_WAITER_ORDER_TAKING"
		);
		cafeWaiterOrderAcceptText = getNarration(
			messages,
			"CAFE_WAITER_ORDER_ACCEPT"
		);
		cafeFriendConversationOneText = getNarration(
			messages,
			"CAFE_FRIEND_CONVERSATION_ONE"
		);
		cafeFriendConversationTwoText = getNarration(
			messages,
			"CAFE_FRIEND_CONVERSATION_TWO"
		);
		bargainPurchaseInstructionText = getNarration(
			messages,
			"BARGAIN_PURCHASE_INSTRUCTION"
		);
		textSpa = getNarration(messages, "TEXT_SPA");
		textCasino = getNarration(messages, "TEXT_CASINO");
		textFood = getNarration(messages, "TEXT_FOOD");
		textShopping = getNarration(messages, "TEXT_SHOPPING");
		textGym = getNarration(messages, "TEXT_GYM");
		textHoliday = getNarration(messages, "TEXT_HOLIDAY");
		textClubNight = getNarration(messages, "TEXT_CLUB_NIGHT");
		textConcert = getNarration(messages, "TEXT_CONCERT");
		textOnlineGame = getNarration(messages, "TEXT_ONLINE_GAME");

		bargainShopkeeperOneText = getNarration(messages, "BARGAIN_SHOPKEEPER_ONE");
		textBargain = getNarration(messages, "TEXT_BARGAIN");
		textAccept = getNarration(messages, "TEXT_ACCEPT");
		bargainAcceptText = getNarration(messages, "BARGAIN_ACCEPT");
		bargainShopkeeperThankingText = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_THANKING"
		);
		beachAvatarExploreText = getNarration(messages, "BEACH_AVATAR_THINK_ONE");
		bargainShopKeeperTwoText = getNarration(messages, "BARGAIN_SHOPKEEPER_TWO");
		beachAvatarThinkTwoText = getNarration(messages, "BEACH_AVATAR_THINK_TWO");
		marketShopkeeperOneText = getNarration(messages, "MARKET_SHOPKEEPER_ONE");
		bargainShopkeeperThreetext = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_THREE"
		);
		textAskText = getNarration(messages, "TEXT_ASK");
		bargainShopkeeperFourText = getNarration(
			messages,
			"BARGAIN_SHOPKEEPER_FOUR"
		);
		textDecline = getNarration(messages, "TEXT_DECLINE");
		bargainDeclineText = getNarration(messages, "BARGAIN_DECLINED");
		textCafePlaceOrderText = getNarration(messages, "TEXT_CAFE_PLACE_ORDER");
		marketShoppingInstructionsText = getNarration(
			messages,
			"MARKET_SHOPPING_INSTRUCTIONS"
		);
		marketShopkeeperPackText = getNarration(messages, "MARKET_SHOPKEEPER_PACK");
		feedbackCongratulationText = getNarration(messages, "TEXT_CONGRATULATIONS");
		feedbackScoreText = getNarration(messages, "TEXT_SCORE");
		feedbackDownloadReport = getNarration(messages, "TEXT_DOWNLOAD");
		textDownload = getNarration(messages, "TEXT_DOWNLOAD");
		textDownloadClick = getNarration(messages, "TEXT_DOWNLOAD_CLICK");
		feedbackCheckProductRedirectUrlText = getNarration(
			messages,
			"TEXT_CHECK_PRODUCT"
		);
		textCheckProductClick = getNarration(messages, "TEXT_CHECK_PRODUCT_CLICK");
		textCheckProduct = getNarration(messages, "TEXT_CHECK_PRODUCT");
		feedbackfinalText = getNarration(messages, "TEXT_FINAL_MESSAGE");
		feedbackNQText = getNarration(messages, "TEXT_NQ");
		feedbackEQText = getNarration(messages, "TEXT_EQ");
		feedbackMQText = getNarration(messages, "TEXT_MQ");
		textPhysical = getNarration(messages, "TEXT_PHYSICAL");
		textPsychological = getNarration(messages, "TEXT_PSYCHOLOGICAL");
		textSocial = getNarration(messages, "TEXT_SOCIAL");
		textRankingOne = getNarration(messages, "TEXT_RANKING_ONE");
		textRankingTwo = getNarration(messages, "TEXT_RANKING_TWO");

		bargainNarrations = {
			avatarQuestion,
			shopKeeperReply,
			offerBargain,
			bargainQuote,
			packedText,
			cutOffPrice,
			bargainQuote2,
			finalPrice,
		};
	}

	if (openEndedQuestions) {
		//museum
		museumQuestion = getQuestion(
			openEndedQuestions?.questions,
			"bargain-situation"
		);
		//cafe
		cafeMenuItems = getQuestion(
			openEndedQuestions?.questions,
			"menu-ordering-situation"
		);
		//market
		marketSweetItems = getQuestion(
			openEndedQuestions?.questions,
			"market-situation"
		);
		//Hotel
		wakeUpHours = getQuestion(
			openEndedQuestions?.questions,
			"wakeup-situation"
		);
		homeSleepRoutineQuestion = getQuestion(
			openEndedQuestions?.questions,
			"sleep-situation"
		);
		// Beach stressful situation
		beachStressfulQuestion = getQuestion(
			openEndedQuestions?.questions,
			"beach-stressful-situation"
		);
		//Tower
		ShellCollectionQuestion = getQuestion(
			openEndedQuestions?.questions,
			"beach-shell-collecting-situation"
		);
	}

	if (numeracyQuestions) {
		walletQuestion = getQuestion(numeracyQuestions.questions, "wallet-options");
		bikePetrol = getQuestion(
			numeracyQuestions.questions,
			"scooter-petrol-fill-options"
		);
		busCharge = getQuestion(numeracyQuestions.questions, "bus-charge");
		cabCharge = getQuestion(numeracyQuestions.questions, "cab-charge");
		carPetrol = getQuestion(
			numeracyQuestions.questions,
			"car-petrol-fill-options"
		);
		groceryQuestion = getQuestion(
			numeracyQuestions.questions,
			"banana-options"
		);
		tipQuestion = getQuestion(
			numeracyQuestions.questions,
			"restaurant-tip-options"
		);
		questionPercentage5 = gettipQuestion(
			numeracyQuestions.questions,
			"restaurant-tip-options-5-1"
		);
		questionPercentage10 = gettipQuestion(
			numeracyQuestions.questions,
			"restaurant-tip-options-10-1"
		);
		questionPercentage15 = gettipQuestion(
			numeracyQuestions.questions,
			"restaurant-tip-options-15-1"
		);
		percentageNumeracy = [
			questionPercentage5,
			questionPercentage10,
			questionPercentage15,
		];
	}
	// if (numeracyQuestions) {
	//   //museum
	//   museumQuestion = getQuestion(
	//     openEndedQuestions.questions,
	//     "bargain-situation"
	//   );
	//   //cafe
	//   cafeMenuItems = getQuestion(
	//     openEndedQuestions.questions,
	//     "menu-ordering-situation"
	//   );
	//   //market
	//   marketSweetItems = getQuestion(
	//     openEndedQuestions.questions,
	//     "market-situation"
	//   );
	//   //Hotel
	//   wakeUpHours = getQuestion(openEndedQuestions.questions, "wakeup-situation");
	//   homeSleepRoutineQuestion = getQuestion(
	//     openEndedQuestions.questions,
	//     "sleep-situation"
	//   );
	//   // Beach stressful situation
	//   beachStressfulQuestion = getQuestion(
	//     openEndedQuestions.questions,
	//     "beach-stressful-situation"
	//   );
	//   //Tower
	//   ShellCollectionQuestion = getQuestion(
	//     openEndedQuestions.questions,
	//     "beach-shell-collecting-situation"
	//   );

	//   walletQuestion = getQuestion(numeracyQuestions.questions, "wallet-options");
	//   bikePetrol = getQuestion(
	//     numeracyQuestions.questions,
	//     "scooter-petrol-fill-options"
	//   );
	//   busCharge = getQuestion(numeracyQuestions.questions, "bus-charge");
	//   cabCharge = getQuestion(numeracyQuestions.questions, "cab-charge");
	//   carPetrol = getQuestion(
	//     numeracyQuestions.questions,
	//     "car-petrol-fill-options"
	//   );
	//   groceryQuestion = getQuestion(
	//     numeracyQuestions.questions,
	//     "banana-options"
	//   );
	//   tipQuestion = getQuestion(
	//     numeracyQuestions.questions,
	//     "restaurant-tip-options"
	//   );

	//   questionPercentage5 = gettipQuestion(
	//     numeracyQuestions.questions,
	//     "restaurant-tip-options-5-1"
	//   );
	//   questionPercentage10 = gettipQuestion(
	//     numeracyQuestions.questions,
	//     "restaurant-tip-options-10-1"
	//   );

	//   questionPercentage15 = gettipQuestion(
	//     numeracyQuestions.questions,
	//     "restaurant-tip-options-15-1"
	//   );

	//   percentageNumeracy = [
	//     questionPercentage5,
	//     questionPercentage10,
	//     questionPercentage15,
	//   ];
	// }

	//Images
	if (images) {
		const [imagesList] = images;
		asherImage = getImage(imagesList.content, "ASHER");
	}
	console.log(currentScreen);

	switch (currentScreen) {
		case "REGISTRATION":
			return setComponent(<Registration />);
		case INTIAl_LOADING:
			return setComponent(<IntialLoading />);
		case "PRE_GAME_INTRO":
			return setComponent(
				<IntroScene
					audioClip={introNarration.audioClip}
					message={introNarration.message}
					tapToContinue={tapToContinue}
					currentScreen={currentScreen}
				/>
			);
		case "HOTEL_INTRODUCTION":
			return setComponent(
				<AsherIntro
					audioClip={hotelReceptionistNarration?.audioClipFemale}
					hotelReceptionistText={hotelReceptionistText.message}
					asher={asherImage}
					narration={asherNarration}
					tapToContinue={tapToContinue}
				/>
			);
		case "BEACH_INTRODUCTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<HelpScreen
						beachAvatarAudioMale={beachAvatarFirstAudio?.audioClip}
						beachAvatarAudioFemale={beachAvatarFirstAudio?.audioClipFemale}
						// beachAvatarThinkOneAudioMale={beachAvatarThinkOneAudio?.audioClip}
						// beachAvatarThinkOneAudioFemale={
						//   beachAvatarThinkOneAudio?.audioClipFemale
						// }
						beachAvatarExploreBeachText={beachAvatarExploreBeachText?.message}
						progressText={progressText}
						timeText={timeText}
						tokensText={tokensText}
						tapToContinue={tapToContinue}
					/>
				</WithTracking>
			);
		case "HOTEL_NUMERACY":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<HotelNumeracy
						audioClip={wakeUpHours?.QuestionAudioFemale}
						wakeUpHours={wakeUpHours}
						asher={asherImage}
						narration={avatarSelectionNarration}
					/>
				</WithTracking>
			);
		case "BEACH_TWO_IMAGE_SELECTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<ShoppingApp
						textRankingOne={textRankingOne?.message}
						textRankingTwo={textRankingTwo?.message}
						asher={asherImage}
						narration={saleAnnouncement}
						images={levelThree}
					/>
				</WithTracking>
			);
		case "CAFE_ORDER":
			return setComponent(
				<HomeNumeracy
					audioClip={cafeWaiterOrderTakingNarration?.audioClip}
					cafeWaiterOrdertakingText={cafeWaiterOrdertakingText?.message}
					asher={asherImage}
					questions={walletQuestion}
				/>
			);
		case "CAFE_MENU_SELECTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<VehicleSelection
						audioClip={cafeWaiterOrderAcceptNarration?.audioClip}
						textCafePlaceOrderText={textCafePlaceOrderText.message}
						menuItems={cafeMenuItems}
						asher={asherImage}
						narration={vehicleSelectionNarration}
						vehicleBike={vehicleBike}
						vehicleCar={vehicleCar}
						vehicleTaxi={vehicleTaxi}
						vehicleBus={vehicleBus}
					/>
				</WithTracking>
			);
		case "BIKE_FILL_FUEL_NUMERACY":
			return setComponent(
				<FuelStation bikePetrol={bikePetrol} carPetrol={carPetrol} />
			);
		case "CAB_NUMERACY":
			return setComponent(<Cab questions={cabCharge} />);
		case "BUS_NUMERACY":
			return setComponent(<Bus questions={busCharge} />);
		case "CAFE_ORDER_CONFIRM":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<CafeEnter
						audioClip={cafeWaiterOrderAcceptNarration?.audioClip}
						cafeWaiterOrderAcceptText={cafeWaiterOrderAcceptText?.message}
						asher={asherImage}
						narration={cafeEnterNarration}
						tapToContinue={tapToContinue}
					/>
				</WithTracking>
			);
		case "HOTEL_LIKE_OR_DISLIKE":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<PictureGram
						audioClip={hotelLikeAndDislikeTextNarration?.audioClipFemale}
						images={levelOne}
						textRankingOne={textRankingOne?.message}
						textRankingTwo={textRankingTwo?.message}
						// images={levelFour}
					/>
				</WithTracking>
			);
		case "CAFE_THREE_IMAGE_SELECTION":
			return setComponent(
				<ThreeImage
					textRankingOne={textRankingOne?.message}
					textRankingTwo={textRankingTwo?.message}
					images={levelFour}
				/>
			);
		case "CAFE_FRIEND_MEET":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<StoreIintro
						audioClip={cafeFriendConversationOneNarration?.audioClip}
						cafeFriendConversationOneText={
							cafeFriendConversationOneText?.message
						}
						asher={asherImage}
						tapToContinue={tapToContinue}
						narration={asherStoreEntry}
					/>
				</WithTracking>
			);
		case "STORE_AVATAR_QUERY":
			return setComponent(
				<Bargain
					narration={bargainNarrations}
					label={{
						labelBuy,
						labelLeave,
						labelQuote,
						labelYes,
						labelNo,
						labelAskFor,
					}}
					tapToContinue={tapToContinue}
				/>
			);
		case "AVATAR_REACH_CAFE":
			return setComponent(
				<CafeConversations
					asher={asherImage}
					checkPicturegram={checkPicturegram}
					tapToContinue={tapToContinue}
				/>
			);
		case "AVATAR_FRIEND_CONVERSATION_ONE":
			return setComponent(
				<CafeConversations
					paintingsAdvice={paintingsAdvice}
					paintingsAdviceRequest={paintingsAdviceRequest}
					tapToContinue={tapToContinue}
				/>
			);
		case "AVATAR_FRIEND_CONVERSATION_TWO":
			return setComponent(
				<CafeConversations
					// numeracyQuestions={percentageNumeracy}
					askBill={askBill}
					responseBill={responseBill}
					tapToContinue={tapToContinue}
				/>
			);
		case "CAFE_FRIEND_CONVERSATION_END":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<CafeTipPercentage
						audioClip={cafeFriendConversationTwoNarration?.audioClip}
						cafeFriendConversationTwoText={
							cafeFriendConversationTwoText?.message
						}
						numeracy={tipQuestion}
					/>
				</WithTracking>
			);
		case "CAFE_TIP_PERCENTAGE_NUMERACY":
			return setComponent(<CafeTipQuestion numeracy={percentageNumeracy} />);

		case "MUSEUM_INTRODUCTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<GroceryShop
						audioClipMale={museumQuestion?.QuestionAudioMale}
						audioClipFemale={museumQuestion?.QuestionAudioFemale}
						questions={museumQuestion}
						groceryAvatar={groceryAvatar}
						numeracy={groceryQuestion}
						tapToContinue={tapToContinue}
					/>
				</WithTracking>
			);
		case "MUSEUM_ITEM_SELECTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<ReturnHome
						audioClip={bargainPurchaseInstructionNarration?.audioClip}
						questions={museumQuestion}
						bargainPurchaseInstructionText={
							bargainPurchaseInstructionText.message
						}
						textSpa={textSpa.message}
						textCasino={textCasino.message}
						textFood={textFood.message}
						textShopping={textShopping.message}
						textGym={textGym.message}
						textHoliday={textHoliday.message}
						textClubNight={textClubNight.message}
						textConcert={textConcert.message}
						textOnlineGame={textOnlineGame.message}
						asher={asherImage}
						narration={finalAsherText}
						tapToContinue={tapToContinue}
					/>
				</WithTracking>
			);

		case "MUSEUM_BARGAIN_Q1":
			if (isFeedbackRequired) {
				return setComponent(
					<WithTracking currentScreen={currentScreen}>
						<FeedBack
							bargainShopkeeperThankingTextAudioClip={
								bargainShopkeeperThankingTextAudioClip?.audioClipFemale
							}
							audioClip={bargainShopkeeperOneNarration?.audioClipFemale}
							questions={museumQuestion}
							bargainShopkeeperThankingText={
								bargainShopkeeperThankingText?.message
							}
							bargainShopkeeperOneText={bargainShopkeeperOneText?.message}
							textBargain={textBargain.message}
							textAccept={textAccept?.message}
							buttonText={finalButton?.message}
							finalThanks={finalMessage?.message}
							finalTitle={finalTitle?.message}
						/>
					</WithTracking>
				);
			}
		case "MUSEUM_THREE_BARGAINING_AMOUNT":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<BargainAmount
						audioClipThanking={thankingAudioClip?.audioClipFemale}
						audioClipOkay={okayAudioClip?.audioClipFemale}
						audioClip={bargainAcceptNarration?.audioClip}
						bargainShopkeeperThankingText={
							bargainShopkeeperThankingText.message
						}
						bargainShopkeeperThreetext={bargainShopkeeperThreetext.message}
						bargainAcceptText={bargainAcceptText.message}
					/>
				</WithTracking>
			);

		case "MUSEUM_BARGAIN_Q2":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<FinalBargaining
						audioClipThanking={thankingAudioClip?.audioClipFemale}
						audioClip={bargainShopkeeperTwoNarration?.audioClipFemale}
						textAskText={textAskText.message}
						textAccept={textAccept.message}
						bargainShopkeeperThankingText={
							bargainShopkeeperThankingText.message
						}
						bargainShopKeeperTwoText={bargainShopKeeperTwoText.message}
					/>
				</WithTracking>
			);

		case "MUSEUM_BARGAIN_Q3":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<PurchasedItem
						audioClipThanking={thankingAudioClip?.audioClipFemale}
						bargainSorryIcannotAudioClip={
							bargainSorryIcannotAudio?.audioClipFemale
						}
						bargainDeclinedAudioClip={
							bargainDeclinedNarrationAudio?.audioClipFemale
						}
						bargainShopkeeperThreetext={bargainShopkeeperThreetext.message}
						bargainDeclineText={bargainDeclineText?.message}
						textDecline={textDecline.message}
						textAccept={textAccept.message}
						bargainShopkeeperFourText={bargainShopkeeperFourText.message}
						bargainShopkeeperThankingText={
							bargainShopkeeperThankingText.message
						}
					/>
				</WithTracking>
			);

		case "MARKET_INTRODUCTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<MarketIntro
						marketIntroAudioClip={marketSweetItems?.QuestionAudioMale}
						marketSweetItems={marketSweetItems}
					/>
				</WithTracking>
			);

		case "MARKET_SWEET_SELECTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<MarketSweetSelection
						marketSweetCollectionMaleAudio={
							marketSweetCollectionAudio?.audioClip
						}
						marketShoppingInstructionsText={
							marketShoppingInstructionsText?.message
						}
						marketSweetItems={marketSweetItems}
					/>
				</WithTracking>
			);

		case "MARKET_THANKYOU_SCENE":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<MarketThankYouScene
						marketPackAudioMale={marketLetPackAudioMale?.audioClip}
						marketShopkeeperPackText={marketShopkeeperPackText?.message}
					/>
				</WithTracking>
			);

		case "APP_INTRO":
			return setComponent(<AppIntro navigationText={navigationText.message} />);

		case "GIF_STAR":
			return setComponent(<GifStar />);

		case "APP_INTRO_INSTRUCTIONS":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<AppIntroInstructions
						audioClip={gameIntroNarration?.audioClip}
						appIntroInstructionText={appIntroInstructionText.message}
						currentScreen={currentScreen}
					/>
				</WithTracking>
			);

		case "APP_INTRO_CONTENT_SCENES":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<AppIntroContentScenes
						audioClip5={gameStartNarration?.audioClip}
						audioClip4={explainMenuButtonNarration?.audioClip}
						audioClip3={explainProgressBarNarration?.audioClip}
						audioClip2={explainTimerNarration?.audioClip}
						audioClip1={explainTokenNarration?.audioClip}
						explainTokenText={explainTokenText.message}
						explainTimerText={explainTimerText.message}
						explainProgressText={explainProgressText.message}
						explainMenuButtonText={explainMenuButtonText.message}
						gameStartText={gameStartText.message}
						currentScreen={currentScreen}
					/>
				</WithTracking>
			);

		case "CONGRATS_SCENE":
			return setComponent(<CongratsScene />);

		//ANil
		case "GAME_AVATAR_CHOOSE":
			return setComponent(
				<WithTracking>
					<AvatarChoose
						audioClip={textAvatarChooseNarration?.audioClip}
						avatarChoosetext={avatarChoosetext.message}
						nextText={nextText.message}
						currentScreen={currentScreen}
					/>
				</WithTracking>
			);

		case "BEACH_LAND_TOWER":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<BeachTower
						beachAvatarThinkOneAudioMale={
							beachAvatarThinkOneTowerAudio?.audioClip
						}
						beachAvatarThinkOneAudioFemale={
							beachAvatarThinkOneTowerAudio?.audioClipFemale
						}
						beachAvatarExploreText={beachAvatarExploreText}
					/>
				</WithTracking>
			);

		case "BEACH_SHELL_COLLECTION":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<BeachShellCollection
						shellCollectionAudioMale={
							ShellCollectionQuestion?.QuestionAudioMale
						}
						ShellCollectionQuestion={ShellCollectionQuestion}
					/>
				</WithTracking>
			);

		case "BEACH_END":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<BeachThanks
						beachAvatarThinkTwoAudioMale={beachAvatarThinkTwoTower?.audioClip}
						beachAvatarThinkTwoAudioFemale={
							beachAvatarThinkTwoTower?.audioClipFemale
						}
						beachAvatarThinkTwoText={beachAvatarThinkTwoText.message}
					/>
				</WithTracking>
			);

		case "GAME_REVAMP_FEEDBACK":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<GameRevampFeedback
						textCongratulationsMaleAudio={textCongratulationsAudio?.audioClip}
						feedbackCongratulationText={feedbackCongratulationText.message}
						feedbackScoreText={feedbackScoreText.message}
						feedbackfinalText={feedbackfinalText.message}
						feedbackNQText={feedbackNQText.message}
						feedbackEQText={feedbackEQText.message}
						feedbackMQText={feedbackMQText.message}
						textPhysical={textPhysical?.message}
						textPsychological={textPsychological?.message}
						textSocial={textSocial?.message}
						textDownload={textDownload?.message}
						textDownloadClick={textDownloadClick?.message}
						textCheckProductClick={textCheckProductClick?.message}
						textCheckProduct={textCheckProduct?.message}
					/>
				</WithTracking>
			);

		case "GIF_STAR_TWO":
			return setComponent(<GifStarTwo />);

		case "INTRO_HOTEL":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<IntroHotel
						audioClip={navigationTextNarration?.audioClip}
						navigationTextNarration={navigationTextNarration?.audioClip}
						navigationText={navigationText.message}
					/>
				</WithTracking>
			);

		case "INTRO_CAFE":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<IntroCafe
						audioClip={navigationTextNarration?.audioClip}
						navigationText={navigationText.message}
						currentScreen={currentScreen}
					/>
				</WithTracking>
			);

		case "INTRO_MUSEUM":
			return setComponent(
				<IntroMuseum
					audioClip={navigationTextNarration?.audioClip}
					navigationText={navigationText.message}
				/>
			);

		case "INTRO_BEACH":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<IntroBeach
						audioClip={navigationTextNarration?.audioClip}
						navigationText={navigationText.message}
					/>
				</WithTracking>
			);

		case "INTRO_TOWER":
			return setComponent(
				<IntroTower
					audioClip={navigationTextNarration?.audioClip}
					navigationText={navigationText.message}
				/>
			);

		case "INTRO_MARKET":
			return setComponent(
				<IntroMarket
					audioClip={navigationTextNarration?.audioClip}
					navigationText={navigationText.message}
				/>
			);

		case "CAFE_STAR":
			return setComponent(<CafeStar />);

		case "HOTEL_STAR":
			return setComponent(<HotelStar />);

		case "MUSEUM_STAR":
			return setComponent(<MuseumStar />);

		case "TOWER_STAR":
			return setComponent(<TowerStar />);

		case "BEACH_STAR":
			return setComponent(<BeachStar />);

		case "MARKET_STAR":
			return setComponent(<MarketStar />);

		case "INITIAL_REVAMP_FEEDBACK":
			return setComponent(
				<RevampFeedback
					feedbackCongratulationText={feedbackCongratulationText.message}
					feedbackScoreText={feedbackScoreText.message}
					feedbackfinalText={feedbackfinalText.message}
					feedbackNQText={feedbackNQText.message}
					feedbackEQText={feedbackEQText.message}
					feedbackMQText={feedbackMQText.message}
				/>
			);
		// Beach Stressful Scene and new scene and components
		case "BEACH_STRESSFUL_SCENE":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<BeachStressful
						beachStressfullAudioMale={beachStressfulQuestion?.QuestionAudioMale}
						beachStressfulQuestion={beachStressfulQuestion}
					/>
				</WithTracking>
			);

		case "HOME_SLEEP_ROUTINE":
			return setComponent(
				<WithTracking currentScreen={currentScreen}>
					<HomeSleepRoutine
						audioClip={homeSleepRoutineQuestion?.QuestionAudioFemale}
						sleepRoutine={homeSleepRoutineQuestion}
					/>
				</WithTracking>
			);

		case "START_SCREEN":
			return setComponent(<StartScreen textStart={textStart?.message} />);

			return setComponent(
				<EndGame finalButton={finalButton} finalMessage={finalMessage} />
			);

		default:
			return component;
	}
};

export default setScreens;
