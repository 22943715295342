//Intialize
export const INTIALIZE_SUCCESS = "INTIALIZE_SUCCESS";
export const SAVE_LANGUAGES = "SAVE_LANGUAGES";
export const SAVE_ZONES = "SAVE_ZONES";
export const IS_FEEDBACK_REQUIRED = "IS_FEEDBACK_REQUIRED";

//User Registration
export const SAVE_USER_REGISTRATION_INFO = "SAVE_USER_REGISTRATION_INFO";

//Tracking
export const IS_LOADED = "IS__LOADED";
export const Is_USER_SUBMITTED_DATA = "Is_USER_SUBMITTED_DATA";
export const SAVE_USER_SESSION_ID = "SAVE_USER_SESSION_ID";
export const SAVE_AVATAR_ID = "SAVE_AVATAR_ID";
export const VEHICLE_SELECTION = "VEHICLE_SELECTION";
export const ASK_BARGAIN = "SAVE_BARGAIN";
export const FIRST_BARGAIN_VALUE = "FIRST_BARGAIN_VALUE";
export const SECOND_BARGAIN_VALUE = "SECOND_BARGAIN_VALUE";
export const SAVE_PICTURE_GRAM = "SAVE_PICTURE_GRAM";
export const SAVE_PICTURE_GRAM_COINS = "SAVE_PICTURE_GRAM_COINS";
export const SAVE_SHOPPING_APP = "SAVE_SHOPPING_APP";
export const SAVE_THREE_IMAGES = "SAVE_THREE_IMAGES";
export const SAVE_NUMERACY = "SAVE_NUMERACY";
export const ADD_COINS = "ADD_COINS";
export const SUBTRACT_COINS = "SUBTRACT_COINS";
export const TWO_IMAGE_SELECTION_TIME = "TWO_IMAGE_SELECTION_TIME";
export const TWO_IMAGE_SELECTION_GAINED_COINS =
  "TWO_IMAGE_SELECTION_GAINED_COINS";
export const THREE_IMAGE_SELECTION_TIME = "THREE_IMAGE_SELECTION_TIME";
export const THREE_IMAGE_SELECTION_GAINED_COINS =
  "THREE_IMAGE_SELECTION_GAINED_COINS";
export const PICTUREGRAM_SELECTION_TIME = "PICTUREGRAM_SELECTION_TIME";
export const NUMERACY_SELECTION_TIME = "NUMERACY_SELECTION_TIME";
export const TOTAL_TIME_TAKEN_IN_SEC = "TOTAL_TIME_TAKEN_IN_SEC";
export const TOTAL_TIME_TAKEN_IN_MILLI_SEC = "TOTAL_TIME_TAKEN_IN_MILLI_SEC";
export const SAVE_RESPONSE = "SAVE_RESPONSE";
export const RESET_DATA = "RESET_DATA";
export const OPEN_ENDED_QUESTION = "OPEN_ENDED_QUESTION";
export const SAVE_NEW_THREE_RANKING_IMAGES = "SAVE_NEW_THREE_RANKING_IMAGES";
export const INSURANCE_GAME_HOTEL_SELECTION_TIME =
  "INSURANCE_GAME_HOTEL_SELECTION_TIME";
export const INSURANCE_GAME_CAFE_SELECTION_TIME =
  "INSURANCE_GAME_CAFE_SELECTION_TIME";
export const INSURANCE_GAME_BEACH_SELECTION_TIME =
  "INSURANCE_GAME_BEACH_SELECTION_TIME";

//ShuffleData
export const SAVE_SHUFFLE_AUDIO = "SAVE_SHUFFLE_AUDIO";
export const SAVE_IMAGE_DATA_SET = "SAVE_IMAGE_DATA_SET";
export const SAVE_LEVELS = "LEVELS";
export const SAVE_NARRATION_DATA_SET = "SAVE_NARRATION_DATA_SET";
export const SAVE_SCENE_DATA_LIST = "SAVE_SCENE_DATA_LIST";
export const SAVE_GAME_SESSION_ID = "SAVE_GAME_SESSION_ID";
export const SAVE_SCENE_ID = "SAVE_SCENE_ID";

export const REGISTER = "REGISTER";
export const USER_REGISTER = "USER_REGISTER";
export const FETCH_SHUFFLE = "FETCH_SHUFFLE";
export const FETCH_SHUFFLE_SUCCESS = "FETCH_SHUFFLE_SUCCESS";

//Game Transition

export const INTIAl_LOADING = "INTIAl_LOADING";
export const CURRENT_SCREEN_ID = "CURRENT_SCREEN_ID";
export const SET_INDEX = "SET_INDEX";
export const START_STOP_WATCH = "START_STOP_WATCH";
export const STOP_STOP_WATCH = "STOP_STOP_WATCH";
export const AVATAR_GENDER = "AVATAR_GENDER";
export const TOGGLE_MUSIC = "TOGGLE_MUSIC";
export const TOGGLE_VOICE_OVER = "TOGGLE_VOICE_OVER";
export const TIP_QUESTION = "TIP_QUESTION";
export const IS_WINDOW_OPEN = "IS_WINDOW_OPEN";
//Levels
export const SAVE_LEVEL_INFO = "SAVE_LEVEL_INFO";

//MUSEUM
export const MUSEUM_TOTAL_ITEM_PRICE = "MUSEUM_TOTAL_ITEM_PRICE";
export const MUSEUM_MAX_BUDGET = "MUSEUM_MAX_BUDGET";
export const MUSEUM_BARGAIN_OPEN_ENDED_QUESTION =
  "MUSEUM_BARGAIN_OPEN_ENDED_QUESTION";

// MARKET
export const MARKET_MAX_REQUIRED_BOX_COUNT = "MARKET_MAX_REQUIRED_BOX_COUNT";
export const MARKET_OPEN_ENDED_QUESTION = "MARKET_OPEN_ENDED_QUESTION";

// Module ends
export const IS_HOTEL_MODULE_END = "IS_HOTEL_MODULE_END";
export const IS_CAFE_MODULE_END = "IS_CAFE_MODULE_END";
export const IS_MUSEUM_MODULE_END = "IS_MUSEUM_MODULE_END";
export const IS_BEACH_MODULE_END = "IS_BEACH_MODULE_END";
export const IS_TOWER_ISLAND_MODULE_END = "IS_TOWER_ISLAND_MODULE_END";
export const IS_MARKET_MODULE_END = "IS_MARKET_MODULE_END";

//Timer
export const TIMER_START = "TIMER_START";
export const HOTEL_STAR = "HOTEL_STAR";
export const CAFE_STAR = "CAFE_STAR";
export const MUSEUM_STAR = "MUSEUM_STAR";
export const BEACH_STAR = "BEACH_STAR";
export const TOWER_STAR = "TOWER_STAR";
export const MARKET_STAR = "MARKET_STAR";

// COINS
export const GAINED_COINS_BARGAIN_SCENE = "GAINED_COINS_BARGAIN_SCENE";

//TOP BAR CAFETOPUIPANEL
export const CAFE_TOP_UI_PANEL = "CAFE_TOP_UI_PANEL";

//START SCREEN
export const START_SCREEN = "START_SCREEN";

//PDF download report
export const PDF_REPORT_DOWNLOAD = "PDF_REPORT_DOWNLOAD";
export const PDF_START_FETCHING = "PDF_START_FETCHING";
