import React from "react";
// import logo from '../../assets/images/backgrounds/LOGO_PAGE.jpg';
import "./style.scss";
// import logo from '../../assets/images/LOGO.png';
import logo from "../../assets/images/Tata AIA Life Logo.png";

const SplasScreen = () => {
  return (
    <>
      <div className="image__container">
        <img src={logo} alt="logo" className="logo__main" />
      </div>
    </>
  );
};

export default SplasScreen;
