import React from "react";
import cafeTopUiPanelImage from "../../../../assets/images/cafe_revamp/top_ui_panel.png";
// import topBar from "../../../../assets/images/cafe_revamp/top_bar.png";
import { connect } from "react-redux";
import Audiocontrols from "../../../common/audiocontrols";
import "./style.scss";

const CafeTopUiPanel = ({ coins }) => {
  return (
    <div className="top_con">
      <Audiocontrols />
      <img src={cafeTopUiPanelImage} alt="top_banner" className="top_ui" />
      <div className="coins_dis">{coins}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coins: state.trackingUser.user.coins,
    currentsceneId: state.screenChange.screenID,
    sceneDataList: state.shuffleData.sceneDataList,
    screenID: state.screenChange.screenID,
  };
};

export default connect(mapStateToProps)(CafeTopUiPanel);
