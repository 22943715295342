import { BARGAIN_QUESTION_IDS, QUESTION_SET_IDS } from "../../constants";
import { MUSEUM_BARGAIN_OPEN_ENDED_QUESTION, MUSEUM_MAX_BUDGET, MUSEUM_TOTAL_ITEM_PRICE } from "../types/index";
const initialState = {
  isLoaded: false,
  price: 0,
  maxBudget: 0,
  bargainOpenEndedQuestions: {
    questionSetId: QUESTION_SET_IDS.BARGAIN_SITUATION,
    questionId: "",
    gainedCoins: 0,
    question: [
      {
          questionId: BARGAIN_QUESTION_IDS.SQ_PURCHASE_AMOUNT,
          optionId: "",
          timeTakenMillis: 0,
          coin: 0
      },
      {
          questionId: BARGAIN_QUESTION_IDS.SQ_BARGAIN,
          optionId: "",
          timeTakenMillis: 0,
          coin: 0
      },
      {
          questionId: BARGAIN_QUESTION_IDS.SQ_BARGAIN_1,
          optionId: "",
          timeTakenMillis: 0,
          coin: 0
      },
      {
          questionId: BARGAIN_QUESTION_IDS.SQ_BARGAIN_2,
          optionId: "",
          timeTakenMillis: 0,
          coin: 0,
          accept_status: "",
      }
    ]
  },
};
const museumReducer = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_TOTAL_ITEM_PRICE:
      return { ...state, isLoaded: true, price: action.payload };
      case MUSEUM_MAX_BUDGET:
        return {...state, maxBudget: action.payload}
      case MUSEUM_BARGAIN_OPEN_ENDED_QUESTION:
        return {...state, bargainOpenEndedQuestions: action.payload }
    default:
      return state;
  }
};
export default museumReducer;
