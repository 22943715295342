import React, { useEffect, useState, useMemo } from "react";
import cafeBottomUiPanelImage from "../../../../assets/images/cafe_revamp/bottom_ui_panel.png";
import cafeBottomUiStar1PanelImage from "../../../../assets/images/cafe_revamp/bottom_ui_panel_star1.png";
import timerImage from "../../../../assets/images/anil_revamp_sets/timer.png";
import star from "../../../../assets/images/star.png";
import "./style.scss";
import { connect } from "react-redux";
import Counter from "../../../../utils/useCounter";

const CafeBottomUiPanel = ({
  moduleStatus,
  startTimer,
  museumStar,
  hotelStar,
  cafeStar,
  beachStar,
  towerStar,
  marketStar,
  narrationDataList,
  lang
}) => {
  const progressText = useMemo(()=>narrationDataList?.find(narration=> narration?.id === "TEXT_PROGRESS")?.message,[narrationDataList])
  return (
    <div className="bottom_con">
      {startTimer ? (
        <div className="chart">
          <Counter/>
        </div>
      ) : (
        <div className="chart_time"></div>
      )}
   
     
       
      
      
      {hotelStar && <img src={star} alt="star" className="hotel_star_img" />}
      {museumStar && <img src={star} alt="star" className="museum_star_img" />}
      {cafeStar && <img src={star} alt="star" className="cafe_star_img" />}
      {beachStar && <img src={star} alt="star" className="beach_star_img" />}
      {towerStar && <img src={star} alt="star" className="tower_star_img" />}
      {marketStar && <img src={star} alt="star" className="market_star_img" />}
      <div data-lang={lang} className="progress_text">
        <span>{progressText}</span>
      </div>
      <img
        src={cafeBottomUiPanelImage}
        alt="bottom_banner"
        className="bottom_ui"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleStatus: state.trackingUser.moduleStatus,
    museumStar: state.progressBar.museumStar,
    hotelStar: state.progressBar.hotelStar,
    cafeStar: state.progressBar.cafeStar,
    beachStar: state.progressBar.beachStar,
    towerStar: state.progressBar.towerStar,
    marketStar: state.progressBar.marketStar,
    narrationDataList: state?.shuffleData?.narrationDataSet?.narrationDataList,
    lang: state?.trackingUser?.user?.lang
  };
};

export default connect(mapStateToProps)(CafeBottomUiPanel);
