import { useState, useEffect } from 'react';

const Counter = () => {
  const [seconds] = useState(180);
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateProgress = () => {
    const progress = (remainingSeconds / seconds) * 100;
    return progress.toFixed(2);
  };

  const timerStyle = {
    width: '200px',
    height: '200px',
    // borderRadius: '50%',
    // backgroundColor: 'lightgray',
    // position: 'relative',
  };

  const progressBarStyle = {
    width: '33%',
    height: '33%',
    borderRadius: '50%',
    background: `conic-gradient(red ${calculateProgress()}%, transparent 0)`,
    transform: 'rotate(3deg)',
    transformOrigin: 'center',
  };

  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '0px',
  };

  return (
    <div style={timerStyle}>
      <div style={progressBarStyle} />
      <div style={textStyle}>
        {Math.floor(remainingSeconds / 60)}:{remainingSeconds % 60 < 10 ? `0${remainingSeconds % 60}` : remainingSeconds % 60}
      </div>
    </div>
  );
};

export default Counter;

